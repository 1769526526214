<template>
  <TooltipButton v-bind="exportContactinfoButton" @click="exportContactinfo()" />
</template>

<script>
import { ExportToCsv } from 'export-to-csv'

import TooltipButton from '@/components/buttons/TooltipButton'

export default {
  name: 'ExportContactinfoButton',
  components: {
    TooltipButton,
  },
  props: {
    participants: {
      type: Array,
      default: () => {
        return []
      },
    },
    filename: {
      type: String,
      default: 'Kontaktinformasjon',
    },
    customHandler: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exportContactinfoButton: {
        button: {
          icon: 'file_download',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: 'Eksporter kontaktinformasjon til .csv',
        },
      },
    }
  },
  methods: {
    exportContactinfo() {
      if (this.customHandler) {
        this.$emit('click')
        return
      }

      if (!this.participants?.length) return

      const participantsSorted = this.participants.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1
        }
        if (a.last_name > b.last_name) {
          return 1
        }
        return 0
      })

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: this.filename,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      }

      const data = participantsSorted.map(participant => {
        return {
          Etternavn: participant.last_name,
          Fornavn: participant.first_name,
          Telefon: participant.phone ? participant.phone : '',
          Epost: participant.email ? participant.email : '',
          Adresse: participant.address ? participant.address : '',
          Postnummer: participant.postalcode ? participant.postalcode.postalcode : '',
          Sted: participant.postalcode ? participant.postalcode.name : '',
          ReservertMotEpostmarkedsføring: participant.disallows_spam ? 'Ja' : 'Nei',
          ReservertMotFoto: participant.disallows_photos ? 'Ja' : 'Nei',
        }
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
  },
}
</script>
