import { Model } from '@vuex-orm/core'

export default class BaseModel extends Model {
  get primaryKey() {
    let primaryKey = { isComposite: false, key: {} }

    const keys = this.constructor.primaryKey

    if (Array.isArray(keys)) {
      primaryKey.isComposite = true

      keys.forEach(key => {
        primaryKey.key[key] = this[key]
      })
    } else {
      primaryKey.key[keys] = this[keys]
    }

    return primaryKey
  }

  static createOrUpdateOne(id, data = {}, config = {}) {
    if (id) {
      return this.updateOne(id, data, config)
    }
    return this.createOne(data, config)
  }

  static async createOne(data = {}, config = {}) {
    if (typeof data != 'object') {
      const transformedData = {}
      transformedData[this.dataField] = data
      data = transformedData
    }

    const url = `${this.entity}`
    const result = await this.api().post(url, data, config)
    return result.entities[this.entity][0]
  }

  static fetchAll(params, config) {
    let searchQuery = ''
    if (params && params.search) {
      searchQuery = `?q=${JSON.stringify(params.search)}`
    }

    const url = `${this.entity}${searchQuery}`
    return this.api().get(url, config)
  }

  static async fetchOneById(id, deleteAll = false, config = {}) {
    if (!id) return

    if (deleteAll) {
      this.deleteAll()
    }

    const response = await this.api().get(`${this.entity}/${id}`, config)

    if (response && response.entities && response.entities[this.entity]) {
      return response.entities[this.entity][0]
    }
  }

  static async fetchOneByQuery(search, sortBy, deleteAll = false) {
    const result = await this.fetchPaginated({
      search,
      deleteAll,
      pagination: { itemsPerPage: 1, page: 1, sortBy: {} },
    })

    if (result.entities[this.entity]) {
      return result.entities[this.entity][0]
    }
  }

  static async fetchBatch(ids, deleteAll = true, config = {}) {
    if (!ids) return

    if (deleteAll) {
      this.deleteAll()
    }

    return this.api().get(`${this.entity}?ids=${ids.toString()}`, config)
  }

  static fetchByKey(key, id, deleteAll = true, config) {
    if (!key) return
    if (!id) return

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?key=${key}&id=${id}`
    return this.api().get(url, config)
  }

  static fetchBatchByKey(key, ids, deleteAll = true, config = {}) {
    if (!key) return
    if (!ids) return

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?key=${key}&ids=${ids.toString()}`
    return this.api().get(url, config)
  }

  static fetchPaginated({ search, pagination, deleteAll = true } = {}, config = {}) {
    const searchQuery = this.getSearchQuery(search)
    const sort = this.getSort(pagination)

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?per_page=${pagination.itemsPerPage}&page=${pagination.page}${sort}${searchQuery}`

    return this.api().get(url, { dataKey: 'data', ...config })
  }

  static async fetchBatchByFirstField(ids, deleteAll = true) {
    if (!ids) return

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?id1=${ids.toString()}`
    return this.api().get(url)
  }

  static async fetchBatchBySecondField(ids, deleteAll = true) {
    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?id2=${ids.toString()}`
    return this.api().get(url)
  }

  static async updateOne(id, data, config = {}) {
    if (!Number.isInteger(id) && this.primaryKey.length > 1) {
      id = id.replace('[', '')
      id = id.replace(']', '')
      id = id.replace(',', '/')
    }
    const url = `${this.entity}/${id}`
    const result = await this.api().put(url, data, config)
    return result.entities[this.entity][0]
  }

  static deleteOne(id) {
    let url = `${this.entity}`

    if (Number.isInteger(id)) {
      url = `${url}/${id}`
      return this.api().delete(url, { delete: id })
    }

    if (this.primaryKey.length > 1) {
      const compositeKey = []

      this.primaryKey.forEach(key => {
        compositeKey.push(id[key])
      })

      return this.api().delete(url, { params: id, delete: compositeKey })
    }
  }

  delete() {
    const url = this.constructor.entity

    return this.constructor
      .api()
      .delete(url, { params: this.primaryKey.key, delete: JSON.parse(this.$id) })
  }

  static getSearchQuery(search) {
    for (const [field, value] of Object.entries(search)) {
      if (value === null && !this.isNullable(field)) {
        search[field] = undefined
      }
    }

    let searchString = JSON.stringify(search)

    if (searchString == '{}') {
      return ''
    }

    return `&q=${searchString}`
  }

  static getSort(pagination) {
    if (!pagination.sortBy.length) return ''
    if (pagination.sortBy.length != pagination.sortDesc.length) return ''

    let order = '&order='

    //add all sort fields and directions
    pagination.sortBy.forEach((sortField, i) => {
      //make sure that we are trying to order by a valid field
      if (this.fields()[sortField]) {
        const direction = pagination.sortDesc[i] ? '.desc' : '.asc'
        order = `${order}${sortField}${direction}&`
      }
    })

    //remove trailing &
    order = order.slice(0, -1)

    return order
  }

  static isNullable(field) {
    return this.fields()[field].isNullable
  }
}
