import BaseModel from '@/models/BaseModel'

export default class Communicationcategory extends BaseModel {
  static entity = 'communicationcategories'
  static primaryKey = 'communicationcategory_id'

  static fields() {
    return {
      communicationcategory_id: this.number(null),
      name: this.string(),
    }
  }
}
