export default {
  methods: {
    getDistinctIdsFromEntities(response, field) {
      if (!response) {
        return
      }

      if (response.entities[response.model.entity]) {
        const ids = response.entities[response.model.entity].map(entity => entity[field])

        let distinctIds = [...new Set(ids)]
        distinctIds = distinctIds.filter(id => id != null)

        return distinctIds
      }
    },
  },
}
