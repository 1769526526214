import Course from '@/models/Course'
import Coursetype from '@/models/Coursetype'
import Lodgingtype from '@/models/Lodgingtype'
import Participant from '@/models/Participant'
import Participation from '@/models/Participation'
import Trafficsource from '@/models/Trafficsource'

export default {
  data: () => {
    return {}
  },
  computed: {
    participationDialog() {
      const fields = [
        {
          name: 'participant_id',
          type: 'lookupAutocomplete',
          label: 'Deltaker',
          item: this.selectedParticipation ? this.selectedParticipation.participant : null,
          itemText: 'full_name',
          itemValue: 'participant_id',
          loading: this.loading,
          disabled: this.type == 'participant' ? true : false,
          model: Participant,
          filters: [{ key: 'deceased', value: false }],
          rules: [this.$validationRules.required],
        },
        {
          name: 'course_id',
          type: 'lookupAutocomplete',
          label: 'Kurs',
          item: this.selectedParticipation ? this.selectedParticipation.course : null,
          itemText: 'title',
          itemValue: 'course_id',
          loading: this.loading,
          disabled: this.type == 'course' ? true : false,
          model: Course,
          inputHandler: (fieldName, value) => {
            const course = Course.find(value)
            if (course) {
              this.$refs.participationDialog.entity.coursetype_id = course.coursetype_id
            }
            this.$refs.participationDialog.handleField(fieldName, value)
          },
          rules: [this.$validationRules.required],
        },
        {
          name: 'trafficsource_id',
          type: 'select',
          label: 'Kilde',
          items: Trafficsource.query().get(),
          itemText: 'name',
          itemValue: 'trafficsource_id',
          rules: [this.$validationRules.required],
        },
      ]

      //TODO remove switch and refactor into methods - see coursedialog
      if (this.course) {
        switch (this.course.coursetype_id) {
          case Coursetype.LOCAL_COURSE:
            fields.push(
              {
                name: 'lodgingtype_id',
                type: 'select',
                label: 'Innlosjering',
                items: Lodgingtype.query().get(),
                itemText: 'name',
                itemValue: 'lodgingtype_id',
                clearable: true,
              },
              {
                name: 'lodging_date_from',
                type: 'text',
                textInputType: 'date',
                label: 'Innlosjering fra',
                clearable: true,
              },
              {
                name: 'lodging_date_to',
                type: 'text',
                textInputType: 'date',
                label: 'Innlosjering til',
                clearable: true,
              }
            )
            break

          case Coursetype.TRAVEL_COURSE:
            fields.push(
              {
                name: 'lodgingtype_id',
                type: 'select',
                label: 'Innlosjering',
                items: Lodgingtype.query().get(),
                itemText: 'name',
                itemValue: 'lodgingtype_id',
                clearable: true,
              },
              {
                name: 'airport_shuttle',
                type: 'textarea',
                label: 'Buss (Værnes)',
                clearable: true,
                rows: 1,
                autoGrow: true,
              },
              {
                name: 'airport_lodging',
                type: 'textarea',
                label: 'Overnatting (Værnes)',
                clearable: true,
                rows: 1,
                autoGrow: true,
              },
              {
                name: 'will_reunite',
                type: 'switch',
                label: 'Skal delta på ettersamling',
              },
              {
                name: 'invoicing_info',
                type: 'textarea',
                label: 'Faktureringsinformasjon',
                clearable: true,
                rows: 1,
                autoGrow: true,
              }
            )

            break
        }
      }

      fields.push(
        {
          name: 'interest_date',
          type: 'text',
          textInputType: 'date',
          label: 'Interessert dato',
        },
        {
          name: 'signup_date',
          type: 'text',
          textInputType: 'date',
          label: 'Venteliste dato',
          clearable: true,
        },
        {
          name: 'confirmed_date',
          type: 'text',
          textInputType: 'date',
          label: 'Påmeldt dato',
          clearable: true,
        },
        {
          name: 'cancel_date',
          type: 'text',
          textInputType: 'date',
          label: 'Avmeldt dato',
          clearable: true,
        },
        {
          name: 'hours_missed',
          type: 'text',
          textInputType: 'number',
          label: 'Fravær i timer',
          clearable: true,
        },
        {
          name: 'comment',
          type: 'textarea',
          label: 'Kommentar',
          rows: 1,
          autoGrow: true,
        }
      )

      const participationDialog = {
        createTitle: 'Opprett ny deltakelse',
        editTitle: 'Rediger deltakelse',
        maxWidth: 640,
        fields: fields,
      }
      return participationDialog
    },
    createParticipationButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('PARTICIPATION_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PARTICIPATION_CREATE')
            ? 'Opprett deltakelse'
            : 'Du mangler tillatelse til å opprette deltakelser',
        },
      }
    },
    updateParticipationButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('PARTICIPATION_UPDATE') || this.loading,
          color: this.$currentUser.preferences.darkMode ? 'white' : '',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PARTICIPATION_UPDATE')
            ? 'Rediger deltakelse'
            : 'Du mangler tillatelse til å redigere deltakelser',
        },
      }
    },
  },
  methods: {
    showParticipationDialog(participation) {
      this.selectedParticipation = participation

      this.$refs.participationDialog.showDialog(Participation, participation)
    },
    handleParticipationCreated(participation) {
      this.selectedParticipation = Participation.query()
        .where('participation_id', participation.participation_id)
        .withAll()
        .first()

      this.$refs.participationDialog.entity = participation
      this.$successToast(`Deltakelse opprettet`)
    },
    handleParticipationUpdated(participation) {
      this.$refs.participationDialog.entity = Participation.query()
        .where('participation_id', participation.participation_id)
        .withAll()
        .first()

      this.$successToast(`Endringen av deltakelsen er lagret`)
    },
  },
}
