<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row class="mb-16">
      <v-col cols="12" lg="4">
        <InterestedParticipantsCard
          color="success"
          title="Interessert i dette kurset"
          :participants="participantsInterestedInCourse"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <InterestedParticipantsCard
          color="primary"
          :title="`Interessert i ${course.coursetype.name}`"
          :participants="participantsInterestedInCoursetype"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <InterestedParticipantsCard
          color="primary"
          :title="`Deltatt på ${course.coursecategory.name}-kurs`"
          :participants="participantsInterestedInCoursecategory"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InterestedParticipantsCard from '@/components/InterestedParticipantsCard'

import courseDialog from '@/mixins/dialogs/courseDialog'
import responseHelper from '@/mixins/responseHelper.js'

import Course from '@/models/Course'
import Participant from '@/models/Participant'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'
import Participation from '@/models/Participation'
import Postalcode from '@/models/Postalcode'

export default {
  components: { InterestedParticipantsCard },
  mixins: [courseDialog, responseHelper],
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    course() {
      return Course.query()
        .whereId(parseInt(this.$route.params.course_id))
        .with('coursetype')
        .with('coursecategory')
        .with('participations')
        .first()
    },
    participantsInterestedInCourse() {
      return Participant.query()
        .with('postalcode')
        .where('deceased', false)
        .whereHas('participations', query => {
          query
            .where('course_id', this.course.course_id)
            .where('confirmed_date', null)
            .where('signup_date', null)
            .where('cancel_date', null)
        })
        .orderBy('last_name')
        .get()
    },
    participantsInterestedInCoursetype() {
      return Participant.query()
        .with('postalcode')
        .where('deceased', false)
        .whereHas('coursetypes', query => {
          query.where('coursetype_id', this.course.coursetype_id)
        })
        .whereHasNot('participations', query => {
          query.where('course_id', this.course.course_id)
        })
        .orderBy('last_name')
        .get()
    },
    participantsInterestedInCoursecategory() {
      return Participant.query()
        .with('postalcode')
        .where('deceased', false)
        .whereHas('participations', query => {
          query
            .where(participation => {
              return participation.course_id != this.course.course_id
            })
            .whereHas('course', query => {
              query.where('coursecategory_id', this.course.coursecategory_id)
            })
        })
        .orderBy('last_name')
        .get()
    },
  },
  async beforeMount() {
    try {
      /* const course = await Course.fetchOneById(parseInt(this.$route.params.course_id))
      const participantIds = []

      const participations = await Participation.fetchAll()

      const participantsCoursetypes = await ParticipantCoursetype.fetchAll({
        search: { coursetype_id: course.coursetype_id },
      })

      participantIds.push(
        ...this.getDistinctIdsFromEntities(participations, 'participant_id'),
        ...this.getDistinctIdsFromEntities(participantsCoursetypes, 'participant_id')
      )

      await Participant.fetchBatch(participantIds)
      
      //TODO need to make this more effecient and not fetch all
      
      */
      await Course.fetchAll()
      await Participation.fetchAll()
      await Participant.fetchAll()
      await ParticipantCoursetype.fetchAll()
      await Postalcode.fetchAll()
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
    this.loading = false
  },
  methods: {},
}
</script>
