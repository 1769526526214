import BaseModel from '@/models/BaseModel'
import Municipality from '@/models/Municipality'
import Participant from '@/models/Participant'

export default class Postalcode extends BaseModel {
  static entity = 'postalcodes'
  static primaryKey = 'postalcode_id'

  static fields() {
    return {
      postalcode_id: this.number(null),
      municipality_id: this.number(),
      postalcode: this.string(),
      name: this.string(),
      municipality: this.belongsTo(Municipality, 'municipality_id'),
      participants: this.hasMany(Participant, 'postalcode_id'),
    }
  }
}
