<template>
  <v-card>
    <v-toolbar :color="color" class="white--text">
      <v-toolbar-title>
        {{ title }}
        <span v-if="participations" class="caption text--secondary">
          ({{ participations.length
          }}<template v-if="participationType == 'confirmed' && type == 'course'">
            / {{ course.max_participants }}</template
          >)
        </span>
      </v-toolbar-title>
      <v-spacer />
      <TooltipButton v-if="emailAddresses.length" v-bind="sendEmailsButton" @click="sendEmails" />
      <ExportContactinfoButton v-if="participations.length" :participants="participants" />
      <TooltipButton
        v-if="participationType != 'blank' && participationType != 'cancelled'"
        v-bind="createParticipationButton"
        @click="showParticipationDialog(newItem)"
      />
    </v-toolbar>
    <v-progress-linear v-if="loading" indeterminate />
    <v-data-table
      v-else
      :items="participations"
      item-key="participation_id"
      v-bind.sync="participationsTable"
      no-data-text="Fant ikke noe"
      hide-default-footer
      disable-pagination
      sort-by="participant.last_name"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.participation_id"
            :class="
              `${
                type == 'course' && item.participant && item.participant.deceased ? 'deceased' : ''
              }`
            "
          >
            <template v-if="item.participant">
              <template v-if="type == 'course'">
                <td>
                  <div class="d-flex align-center">
                    <div class="mr-1">
                      <router-link
                        :to="{
                          path: `/participants/${item.participant.participant_id}`,
                        }"
                        style="text-decoration: none;"
                      >
                        {{ item.participant.last_name }}, {{ item.participant.first_name }}
                      </router-link>
                      <template v-if="item.participant.birthdate">
                        • {{ item.participant.birthdate | age }} år
                      </template>
                      <div
                        v-if="item.participant.postalcode"
                        class="caption font-weight-light text--secondary text-uppercase nowrap"
                      >
                        {{ item.participant.postalcode.postalcode }}
                        {{ item.participant.postalcode.name }}
                      </div>
                    </div>
                    <IconButtonDialog
                      title="Kontaktinformasjon"
                      tooltip="Vis kontaktinformasjon"
                      :max-width="500"
                      :small="false"
                    >
                      <div v-if="item.participant.email" class="mb-4 ">
                        <v-icon class="mr-2">email</v-icon>
                        <span v-linkified>
                          {{ item.participant.email }}
                        </span>
                      </div>
                      <div class="mb-4">
                        <v-icon class="mr-2">smartphone</v-icon>
                        <a
                          v-if="item.participant.phone"
                          class="nowrap"
                          :href="`tel:${item.participant.phone}`"
                          >{{ item.participant.phone | phone }}
                        </a>
                      </div>
                      <div class="d-flex">
                        <v-icon class="mr-2">place</v-icon>
                        <div>
                          <div>
                            {{ item.participant.address }}
                            <template
                              v-if="item.participant.postalcode && item.participant.address"
                            >
                              ,
                            </template>
                            <template v-if="item.participant.postalcode">
                              {{ item.participant.postalcode.postalcode }}
                              {{ item.participant.postalcode.name }}
                            </template>
                          </div>
                          <div v-if="item.participant.postalcode">
                            <template v-if="item.participant.postalcode.municipality">
                              {{ item.participant.postalcode.municipality.name }} kommune,
                            </template>
                            <template v-if="item.participant.postalcode.municipality.county">
                              {{ item.participant.postalcode.municipality.county.name }}
                            </template>
                          </div>
                        </div>
                      </div>
                    </IconButtonDialog>
                  </div>
                </td>
              </template>
              <template v-if="type == 'participant'">
                <td>
                  <router-link
                    v-if="item.course"
                    :to="{
                      path: `/courses/${item.course.course_id}`,
                    }"
                    style="text-decoration: none;"
                  >
                    {{ item.course.title }}
                  </router-link>
                </td>
              </template>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <template v-if="participationType == 'confirmed' && item.confirmed_date">
                      <span class="nowrap" v-on="on">
                        {{ item.confirmed_date | dayAndMonth }}
                        <v-icon small>search</v-icon>
                      </span>
                    </template>

                    <template v-if="participationType == 'signedUp' && item.signup_date">
                      <span class="nowrap" v-on="on">
                        {{ item.signup_date | dayAndMonth }}
                        <v-icon small>search</v-icon>
                      </span>
                    </template>

                    <template v-if="participationType == 'interested' && item.interest_date">
                      <span class="nowrap" v-on="on">
                        {{ item.interest_date | dayAndMonth }}
                        <v-icon small>search</v-icon>
                      </span>
                    </template>

                    <template v-if="participationType == 'cancelled' && item.cancel_date">
                      <span class="nowrap" v-on="on">
                        {{ item.cancel_date | dayAndMonth }}
                        <v-icon small>search</v-icon>
                      </span>
                    </template>
                  </template>
                  <dl>
                    <template v-if="item.confirmed_date">
                      <dt>Påmeldt</dt>
                      <dd>{{ item.confirmed_date | date }}</dd>
                    </template>
                    <template v-if="item.signup_date">
                      <dt>Venteliste</dt>
                      <dd>{{ item.signup_date | date }}</dd>
                    </template>
                    <template v-if="item.interest_date">
                      <dt>Interessert</dt>
                      <dd>{{ item.interest_date | date }}</dd>
                    </template>
                    <template v-if="item.cancel_date">
                      <dt>Avmeldt</dt>
                      <dd>{{ item.cancel_date | date }}</dd>
                    </template>
                  </dl>
                </v-tooltip>
              </td>
              <td>
                <div class="d-flex align-center">
                  <CommunicationDialogButton
                    type="create"
                    @click="
                      showCommunicationDialog({
                        participation_id: item.participation_id,
                        date: getCurrentDate(),
                      })
                    "
                  />
                  <div class="caption ml-2">
                    <div
                      v-for="communication in item.communications"
                      :key="communication.communication_id"
                      class="nowrap"
                    >
                      {{ communication.date | dayAndMonth }}:
                      {{ communication.communicationcategory.name }}
                      <span class="font-weight-light text--secondary">
                        ({{ communication.communicationtype.name }})
                      </span>
                      <IconButtonDialog
                        v-if="communication.comment"
                        :title="communication.communicationcategory.name"
                        tooltip="Vis kommunikasjon"
                      >
                        <template v-slot:subtitle>
                          {{ communication.date | date }} •
                          {{ communication.communicationtype.name }}
                        </template>
                        <template v-slot:default v-linkified>
                          <div v-linkified>
                            {{ communication.comment }}
                          </div>
                        </template>
                      </IconButtonDialog>
                      <CommunicationDialogButton
                        type="update"
                        @click="showCommunicationDialog(communication)"
                      />
                      <TooltipButton
                        v-bind="deleteCommunicationButton"
                        @click="
                          getDeleteConfirmation(
                            communication,
                            `Bekreft sletting av kommunikasjon`,
                            `Vil du virkelig slette denne kommunikasjonen for alltid?`
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="type == 'course' && item.coursetype_id == LOCAL_COURSE">
                <template v-if="item.lodgingtype">
                  {{ item.lodgingtype.name }}
                  <div v-if="item.lodging_date_from" class="caption">
                    {{ item.lodging_date_from | dayAndMonth }}
                    <template v-if="item.lodging_date_to">
                      - {{ item.lodging_date_to | dayAndMonth }}
                    </template>
                  </div>
                </template>
              </td>
              <td v-if="type == 'course' && item.coursetype_id == TRAVEL_COURSE">
                <template v-if="item.lodgingtype">
                  {{ item.lodgingtype.name }}
                </template>
              </td>
              <td v-if="type == 'course' && item.coursetype_id == TRAVEL_COURSE">
                <template v-if="item.airport_shuttle">
                  {{ item.airport_shuttle | truncateString(17, true) }}
                  <IconButtonDialog
                    v-if="item.airport_shuttle.length > 17"
                    title="Buss Værnes"
                    tooltip="Vis buss Værnes"
                  >
                    {{ item.airport_shuttle }}
                  </IconButtonDialog>
                </template>
              </td>
              <td v-if="type == 'course' && item.coursetype_id == TRAVEL_COURSE">
                <template v-if="item.airport_lodging">
                  {{ item.airport_lodging | truncateString(17, true) }}
                  <IconButtonDialog
                    v-if="item.airport_lodging.length > 17"
                    title="Overnatting Værnes"
                    tooltip="Vis overnatting Værnes"
                  >
                    {{ item.airport_lodging }}
                  </IconButtonDialog>
                </template>
              </td>
              <td v-if="type == 'course' && item.coursetype_id == TRAVEL_COURSE">
                <v-icon v-if="item.will_reunite">check</v-icon>
              </td>
              <td v-if="type == 'course' && item.coursetype_id == TRAVEL_COURSE">
                <template v-if="item.invoicing_info">
                  <span v-linkified>{{ item.invoicing_info | truncateString(17, true) }}</span>
                  <IconButtonDialog
                    v-if="item.invoicing_info.length > 17"
                    title="Faktureringsinformasjon"
                    tooltip="Vis faktureringsinformasjon"
                  >
                    <div v-linkified>{{ item.invoicing_info }}</div>
                  </IconButtonDialog>
                </template>
              </td>
              <td>
                <template v-if="item.participant.important_info">
                  {{ item.participant.important_info | truncateString(17, true) }}
                  <IconButtonDialog
                    v-if="item.participant.important_info.length > 17"
                    title="Viktig informasjon"
                    tooltip="Vis viktig informasjon"
                  >
                    {{ item.participant.important_info }}
                  </IconButtonDialog>
                </template>
              </td>
              <td>
                <template v-if="item.participant.disallows_photos"
                  >Reservert <v-icon small color="error">no_photography</v-icon></template
                >
              </td>
              <td>
                <span v-if="item.comment && item.comment.length < 35" v-linkified>
                  {{ item.comment }}
                </span>
                <template v-else-if="item.comment">
                  {{ item.comment | truncateString(17, true) }}
                  <IconButtonDialog title="Kommentar" tooltip="Vis kommentar" :max-width="600">
                    <pre v-linkified>{{ item.comment }}</pre>
                  </IconButtonDialog>
                </template>
              </td>
              <td>
                <template v-if="item.hours_missed">
                  {{ item.hours_missed }}t
                  <span
                    v-if="item.course.hours_total"
                    class="caption font-weight-light text--secondary"
                  >
                    ({{ Math.round((item.hours_missed / item.course.hours_total) * 100) }}%)
                  </span>
                </template>
              </td>
              <td>
                <TooltipButton
                  v-bind="updateParticipationButton"
                  @click="showParticipationDialog(item)"
                />
                <TooltipButton
                  v-bind="deleteParticipationButton"
                  @click="
                    getDeleteConfirmation(
                      item,
                      `Bekreft sletting av deltakelse`,
                      `Vil du virkelig slette denne deltakelsen for alltid?`
                    )
                  "
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <EntityDialog
      ref="participationDialog"
      v-bind="participationDialog"
      @entityCreated="handleParticipationCreated"
      @entityUpdated="handleParticipationUpdated"
    />

    <EntityDialog
      ref="communicationDialog"
      v-bind="communicationDialog"
      @entityCreated="handleCommunicationCreated"
      @entityUpdated="handleCommunicationUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="closeAndResetDeleteConfirmationDialog(`Du slettet deltakelsen`)"
    />
  </v-card>
</template>

<script>
import CommunicationDialogButton from '@/components/buttons/CommunicationDialogButton'
import TooltipButton from '@/components/buttons/TooltipButton'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import ExportContactinfoButton from '@/components/buttons/ExportContactinfoButton'
import EntityDialog from '@/components/dialogs/EntityDialog'
import IconButtonDialog from '@/components/dialogs/IconButtonDialog'

import coursetypes from '@/mixins/coursetypes'
import dateAndTime from '@/mixins/dateAndTime'
import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import communicationDialog from '@/mixins/dialogs/communicationDialog'
import participationDialog from '@/mixins/dialogs/participationDialog'

import Course from '@/models/Course'
import Coursetype from '@/models/Coursetype'
import Participant from '@/models/Participant'
import Trafficsource from '@/models/Trafficsource'

export default {
  components: {
    CommunicationDialogButton,
    DeleteConfirmationDialog,
    ExportContactinfoButton,
    EntityDialog,
    IconButtonDialog,
    TooltipButton,
  },
  mixins: [
    coursetypes,
    dateAndTime,
    communicationDialog,
    deleteConfirmationDialog,
    participationDialog,
  ],
  props: {
    type: {
      type: String,
      default: '',
    },
    participationType: {
      type: String,
      default: '',
    },
    participations: {
      type: Array,
      default: () => {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: true,
    },
    course: {
      type: Course,
      default: null,
    },
    participant: {
      type: Participant,
      default: null,
    },
  },
  data() {
    return {
      selectedParticipation: null,
    }
  },
  computed: {
    participants() {
      return this.participations.map(participation => participation.participant)
    },
    newItem() {
      const item = {
        trafficsource: this.unknownTrafficSource,
        trafficsource_id: this.unknownTrafficSource.trafficsource_id,
      }

      switch (this.type) {
        case 'course':
          item.course = this.course
          item.course_id = this.course.course_id
          item.coursetype_id = this.course.coursetype_id
          break
        case 'participant':
          item.participant = this.participant
          item.participant_id = this.participant.participant_id
      }

      return item
    },
    unknownTrafficSource() {
      //hardcoded "Unknown" default traffic type
      return Trafficsource.query()
        .where('name', 'Ukjent')
        .first()
    },
    participationsTable() {
      const headers = []

      if (this.type == 'course') {
        headers.push(
          {
            text: 'Deltaker',
            value: 'participant',
            sortable: true,
            sort: (a, b) => (a.last_name > b.last_name ? -1 : 1),
          },
          { text: 'Dato', value: 'dates' },
          { text: 'Kommunikasjon', value: 'communications', sortable: false }
        )

        switch (this.course.coursetype_id) {
          case Coursetype.LOCAL_COURSE:
            headers.push({ text: 'Rom', value: 'lodgingtype.name', sortable: false })
            break
          case Coursetype.TRAVEL_COURSE:
            headers.push({ text: 'Rom', value: 'lodgingtype.name', sortable: false })
            headers.push({ text: 'Buss Værnes', value: 'airport_shuttle', sortable: false })
            headers.push({ text: 'Overnatting Værnes', value: 'airport_lodging', sortable: false })
            headers.push({ text: 'Samling', value: 'will_reunite', sortable: false })
            headers.push({ text: 'Fakturering', value: 'invoicing_info', sortable: false })
            break
        }
      } else if (this.type == 'participant') {
        headers.push(
          {
            text: 'Kurs',
            value: 'course',
            sortable: true,
            sort: (a, b) => (a.title > b.title ? -1 : 1),
          },
          { text: 'Dato', value: 'dates', sortable: false },
          { text: 'Kommunikasjon', value: 'communications', sortable: false }
        )
      }
      headers.push(
        { text: 'Viktig info', value: 'important_info', sortable: false },
        { text: 'Bildebruk', value: 'disallows_photos', sortable: false },
        { text: 'Kommentar', value: 'comment', sortable: false },
        { text: 'Fravær', value: 'hours_missed', sortable: false },
        { text: '', value: 'actions', sortable: false }
      )
      return { headers: headers }
    },
    emailAddresses() {
      if (!this.participations) return []

      return this.participations
        .filter(
          participation =>
            participation.participant?.deceased != true && participation.participant?.email != null
        )
        .map(participation => participation.participant.email)
    },
    color() {
      switch (this.participationType) {
        case 'confirmed':
          return 'success'
        case 'signedUp':
          return 'warning'
        case 'cancelled':
          return 'error'
        default:
          return 'primary'
      }
    },
    title() {
      switch (this.participationType) {
        case 'confirmed':
          if (this.course) return 'Påmeldte deltakere'
          return 'Påmeldt til kurs'
        case 'signedUp':
          if (this.course) return 'Deltakere på venteliste'
          return 'På venteliste til kurs'
        case 'interested':
          if (this.course) return 'Interesserte deltakere'
          return 'Interessert i kurs'
        case 'cancelled':
          if (this.course) return 'Avmeldte deltakere'
          return 'Avmeldt fra kurs'
        case 'blank':
          return 'Deltakere som verken står på interessert-, påmeldt-, avmeldt- eller venteliste'
        default:
          return ''
      }
    },
    deleteParticipationButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('PARTICIPATION_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PARTICIPATION_DELETE')
            ? 'Slett deltakelse'
            : 'Du mangler tillatelse til å slette deltakelser',
        },
      }
    },
    sendEmailsButton() {
      return {
        button: {
          icon:
            this.emailAddresses.length == this.participations.length ? 'mail' : 'mark_email_unread',
          disabled: this.loading,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: `Send epost til ${this.emailAddresses.length} av ${this.participations.length} deltakere`,
        },
      }
    },
    deleteCommunicationButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('COMMUNICATION_DELETE') || this.loading,
          color: 'error',
          xSmall: true,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COMMUNICATION_DELETE')
            ? 'Slett kommunikasjon'
            : 'Du mangler tillatelse til å slette kommunikasjon',
        },
      }
    },
  },
  methods: {
    sendEmails() {
      window.location = `mailto:?bcc=${this.emailAddresses.join(';')}`
    },
  },
}
</script>

<style lang="scss" scoped>
dl {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

dt {
  white-space: nowrap;
  padding-right: 0.25em;

  &:after {
    content: ':';
  }
}

.deceased {
  color: #ff5252;
  text-decoration: line-through;
  text-transform: uppercase;

  a {
    color: inherit;
  }
}
</style>
