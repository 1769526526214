export default {
  computed: {
    interestedParticipations() {
      const where = function(participation) {
        return (
          participation.cancel_date == null &&
          participation.confirmed_date == null &&
          participation.signup_date == null
        )
      }

      return this.getParticipations(where)
    },
    signedUpParticipations() {
      const where = function(participation) {
        return (
          participation.cancel_date == null &&
          participation.confirmed_date == null &&
          participation.signup_date != null
        )
      }

      return this.getParticipations(where)
    },
    confirmedParticipations() {
      const where = function(participation) {
        return participation.cancel_date == null && participation.confirmed_date != null
      }

      return this.getParticipations(where)
    },
    cancelledParticipations() {
      const where = function(participation) {
        return participation.cancel_date != null
      }

      return this.getParticipations(where)
    },
    blankParticipations() {
      const where = function(participation) {
        return (
          participation.interest_date == null &&
          participation.signup_date == null &&
          participation.confirmed_date == null &&
          participation.cancel_date == null
        )
      }

      return this.getParticipations(where)
    },
  },
}
