export default {
  data: () => {
    return {
      confirmationDialog: {
        show: false,
        loading: false,
        title: '',
        text: '',
      },
    }
  },
  methods: {
    showConfirmationDialog(title, text) {
      this.confirmationDialog.title = title
      this.confirmationDialog.text = text
      this.confirmationDialog.show = true
    },
    closeAndResetConfirmationDialog() {
      this.confirmationDialog.title = ''
      this.confirmationDialog.text = ''
      this.confirmationDialog.show = false
    },
  },
}
