<template>
  <v-row class="mb-12">
    <v-col v-for="coursetype in coursetypes" :key="coursetype.coursetype_id">
      <router-link
        :to="{
          path: coursetype.coursetype_id ? `/coursetypes/${coursetype.coursetype_id}` : '/courses',
        }"
        style="text-decoration: none;"
      >
        <v-card
          class="px-16 py-10 nowrap text-center text-h6 rounded white--text"
          :style="`background-color: ${coursetype.color}`"
        >
          {{ coursetype.name }}
        </v-card>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import Coursetype from '@/models/Coursetype'

export default {
  computed: {
    coursetypes() {
      return [{ name: 'Alle kurs', color: '#4caf50' }, ...Coursetype.all()]
    },
  },
}
</script>
