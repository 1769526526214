<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <CoursetypeCards />
    <v-row>
      <v-col cols="12">
        <CoursesTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CoursetypeCards from '@/components/CoursetypeCards'
import CoursesTable from '@/components/datatables/CoursesTable'

export default {
  components: {
    CoursesTable,
    CoursetypeCards,
  },
}
</script>
