import County from '@/models/County'
import Coursetype from '@/models/Coursetype'
import Gender from '@/models/Gender'
import Municipality from '@/models/Municipality'
import Participant from '@/models/Participant'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'
import Postalcode from '@/models/Postalcode'

export default {
  data() {
    return {
      selectedParticipant: null,
    }
  },
  computed: {
    participantDialog() {
      return {
        createTitle: 'Opprett ny deltaker',
        editTitle: 'Rediger deltaker',
        fields: [
          {
            name: 'first_name',
            type: 'text',
            label: 'Fornavn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'last_name',
            type: 'text',
            label: 'Etternavn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'birthdate',
            type: 'text',
            textInputType: 'date',
            label: 'Fødselsdato',
            clearable: true,
          },
          {
            name: 'gender_id',
            label: 'Kjønn',
            rules: [this.$validationRules.required],
            type: 'radio',
            key: 'gender_id',
            items: this.genders,
            value: 'gender_id',
          },
          {
            name: 'phone',
            type: 'text',
            label: 'Telefon',
          },
          {
            name: 'email',
            type: 'text',
            label: 'Epost',
            rules: [this.$validationRules.email],
          },
          {
            name: 'address',
            type: 'text',
            label: 'Adresse',
          },
          {
            name: 'postalcode_id',
            type: 'itemText',
            label: 'Postnummer',
            noDataText: 'Dette postnummeret finnes ikke',
            minLength: 4,
            maxLength: 4,
            item: 'postalcode',
            itemValue: 'postalcode_id',
            itemText: 'postalcode',
            suffixText: 'name',
            model: Postalcode,
            rules: [this.$validationRules.required],
          },
          {
            name: 'important_info',
            type: 'textarea',
            label: 'Viktig informasjon',
            hint: 'Allergier, medisiner etc',
            persistentHint: true,
            rows: 1,
            autoGrow: true,
          },
          {
            name: 'disallows_photos',
            type: 'switch',
            label: 'Reservert mot foto',
          },
          {
            name: 'disallows_spam',
            type: 'switch',
            label: 'Reservert mot epostmarkedsføring',
          },
          {
            name: 'deceased',
            type: 'switch',
            label: 'Avdød',
          },
          {
            name: 'coursetypes',
            type: 'multiselect',
            label: 'Interessert i',
            items: [...Coursetype.all()],
            itemText: 'name',
            itemValue: 'coursetype_id',
            clearable: true,
            changeHandler: this.handleParticipantCoursetypesChange,
          },
        ],
      }
    },
    genders() {
      return Gender.all()
    },
  },
  methods: {
    showParticipantDialog(participant) {
      this.selectedParticipant = participant
      this.$refs.participantDialog.showDialog(Participant, participant)
    },
    handleParticipantCreated(participant) {
      this.$refs.participantDialog.entity = participant
      this.fetchPostalcodeRelations(participant)
      this.$successToast(
        `Du opprettet deltakeren ${participant.last_name}, ${participant.first_name}`
      )
    },
    handleParticipantUpdated(participant) {
      this.$refs.participantDialog.entity = Participant.query()
        .with('postalcode')
        .whereId(participant.participant_id)
        .first()

      this.fetchPostalcodeRelations(participant)
      this.$successToast(
        `Endringen av deltakeren ${participant.last_name}, ${participant.first_name} er lagret`
      )
    },
    handleParticipantCoursetypesChange(field, changes) {
      for (const coursetype of changes.added) {
        ParticipantCoursetype.createOne({
          participant_id: this.$refs.participantDialog.entity.participant_id,
          coursetype_id: coursetype.coursetype_id,
        })
      }
      for (const coursetype of changes.removed) {
        ParticipantCoursetype.deleteOne({
          participant_id: this.$refs.participantDialog.entity.participant_id,
          coursetype_id: coursetype.coursetype_id,
        })
      }
    },
    fetchPostalcodeRelations(instance) {
      const relations = [
        {
          model: Postalcode,
          children: {
            model: Municipality,
            children: {
              model: County,
            },
          },
        },
      ]

      this.fetchRelations(instance, relations)
    },

    async fetchRelations(instance, relations) {
      //TODO does this work for for fetching many to manies?
      if (!relations) return

      if (!Array.isArray(relations)) {
        relations = [relations]
      }

      relations.forEach(async relation => {
        await this.fetchRelation(instance, relation)
      })
    },

    async fetchRelation(instance, related) {
      const relatedInstance = await related.model.fetchOneById(instance[related.model.primaryKey])

      if (relatedInstance) {
        await this.fetchRelations(relatedInstance, related.children)
      }
    },
  },
}
