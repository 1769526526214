export default {
  data: () => {
    return {
      deleteConfirmationDialog: {
        show: false,
        entityToDelete: null,
      },
    }
  },
  methods: {
    getDeleteConfirmation(
      entity,
      title = 'Bekreft sletting',
      text = 'Vil du virkelig slette dette objektet for alltid?'
    ) {
      this.deleteConfirmationDialog.entityToDelete = entity
      this.$refs.deleteConfirmationDialog.showConfirmationDialog(title, text)
    },
    closeAndResetDeleteConfirmationDialog(toastMessage) {
      if (toastMessage) {
        this.$successToast(toastMessage)
      }
      this.deleteConfirmationDialog.entityToDelete = null
      this.$refs.deleteConfirmationDialog.closeAndResetConfirmationDialog()
    },
  },
}
