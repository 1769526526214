import BaseModel from './BaseModel'

export default class ParticipantCoursetype extends BaseModel {
  static entity = 'participants_coursetypes'

  static primaryKey = ['participant_id', 'coursetype_id']

  static fields() {
    return {
      participant_id: this.attr(null),
      coursetype_id: this.attr(null),
    }
  }
}
