import BaseModel from '@/models/BaseModel'
import Coursecategory from '@/models/Coursecategory'
import CourseteacherCoursecategory from '@/models/CourseteacherCoursecategory'

export default class Courseteacher extends BaseModel {
  static entity = 'courseteachers'
  static primaryKey = 'courseteacher_id'

  static fields() {
    return {
      courseteacher_id: this.number(null),
      first_name: this.string(),
      last_name: this.string(),
      full_name: this.string(),
      phone: this.string().nullable(),
      email: this.string().nullable(),
      address: this.string().nullable(),
      contract: this.boolean(),
      inactive: this.boolean(),
      coursecategories: this.belongsToMany(
        Coursecategory,
        CourseteacherCoursecategory,
        'courseteacher_id',
        'coursecategory_id'
      ),
    }
  }
}
