<template>
  <v-app>
    <MainNav />
    <v-main>
      <router-view @toast="toast"></router-view>

      <v-snackbar v-model="snackbar.show" v-bind="snackbar.props">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.show = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import preferences from './mixins/preferences'
import MainNav from './components/MainNav'
import Communicationcategory from '@/models/Communicationcategory'
import Communicationtype from '@/models/Communicationtype'
import County from '@/models/County'
import Coursecategory from '@/models/Coursecategory'
import Courseteacher from '@/models/Courseteacher'
import CourseteacherCoursecategory from '@/models/CourseteacherCoursecategory'
import Coursetype from '@/models/Coursetype'
import Gender from '@/models/Gender'
import Lodgingtype from '@/models/Lodgingtype'
import Municipality from '@/models/Municipality'
import Permission from '@/models/Permission'
import Trafficsource from '@/models/Trafficsource'
import User from '@/models/User'
import UserPermission from '@/models/UserPermission'

export default {
  name: 'App',
  components: {
    MainNav,
  },
  mixins: [preferences],
  data: function() {
    return {
      snackbar: {
        show: false,
        props: this.defaultSnackbarProps,
      },
    }
  },
  computed: {
    defaultSnackbarProps() {
      return {
        color: 'primary',
        bottom: true,
        left: false,
        multiLine: false,
        right: false,
        timeout: -1,
        top: false,
        vertical: false,
      }
    },
  },
  async beforeCreate() {
    try {
      await this.$auth.renewTokens()
    } catch (e) {
      console.log('Error renewing tokens', e)
      return
    }

    try {
      //fetch global data
      await Promise.all([
        Communicationcategory.fetchAll(),
        Communicationtype.fetchAll(),
        County.fetchAll(),
        Coursecategory.fetchAll(),
        Courseteacher.fetchAll(),
        CourseteacherCoursecategory.fetchAll(),
        Coursetype.fetchAll(),
        Gender.fetchAll(),
        Lodgingtype.fetchAll(),
        Municipality.fetchAll(),
        Permission.fetchAll(),
        Trafficsource.fetchAll(),
        UserPermission.fetchAll(),
        User.fetchAll(),
      ])
      await this.initPreferences()
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
  },
  methods: {
    toast(toast) {
      this.snackbar.text = toast.text
      this.snackbar.props = toast.props ? toast.props : this.defaultSnackbarProps
      this.snackbar.show = true
    },
  },
}
</script>

<style lang="less">
@primary: #1976d2;
@secondary: #424242;
@accent: #82b1ff;
@error: #ff5252;
@info: #2196f3;
@success: #4caf50;
@warning: #ffc107;

@blue: #2196f3;
@blue-lighten-5: #e3f2fd;
@blue-lighten-4: #bbdefb;
@blue-lighten-3: #90caf9;
@blue-lighten-2: #64b5f6;
@blue-lighten-1: #42a5f5;
@blue-darken-1: #1e88e5;
@blue-darken-2: #1976d2;
@blue-darken-3: #1565c0;
@blue-darken-4: #0d47a1;
@blue-accent-1: #82b1ff;
@blue-accent-2: #448aff;
@blue-accent-3: #2979ff;
@blue-accent-4: #2962ff;

@grey: #9e9e9e;
@grey-lighten-5: #fafafa;
@grey-lighten-4: #f5f5f5;
@grey-lighten-3: #eeeeee;
@grey-lighten-2: #e0e0e0;
@grey-lighten-1: #bdbdbd;
@grey-darken-1: #757575;
@grey-darken-2: #616161;
@grey-darken-3: #424242;
@grey-darken-4: #212121;

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default;
}

.nowrap {
  white-space: nowrap;
}

.pre-formatted {
  white-space: pre-wrap;
}

.v-card__title {
  word-break: break-word;
}

.v-main__wrap {
  background: #ddd;

  .theme--dark & {
    background-color: transparent;
    background-image: url('./images/dark-bg.png');
    background-size: cover;
  }
}

.v-text-field__details .v-messages__message {
  /* stop validation error messages from overflowing */
  line-height: 1.3;
}

pre {
  font-family: Roboto, sans-serif;
  white-space: pre-wrap;
}

.v-form > :first-child .v-label--active,
.v-form > :first-child .v-label--active + * {
  //add padding to first active floating label
  //(to make space above floating label)
  margin-top: 1rem;
}

.v-textarea textarea {
  line-height: 1.5;
}

.theme--dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.v-tooltip__content {
  pointer-events: initial;
  background: #fff;
  color: #000;
  border: 1px solid #ddd;

  .theme--dark & {
    background: #000;
    color: #fff;
    border: none;
  }
}

//dark theme table background-color on hover
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #333;
}

.pulsating-badge .v-badge__badge {
  animation: pulsating 1.5s infinite ease-in-out;
}

@keyframes pulsating {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
