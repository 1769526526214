<template>
  <v-container v-if="$currentUser" class="py-16 px-0">
    <v-row class="mb-16">
      <v-col cols="12">
        <v-card height="100%">
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Kursstatistikk 2021</v-toolbar-title>
          </v-toolbar>
          <v-data-table
            :items="data"
            item-key="course_id"
            :loading="loading"
            v-bind="dataTable"
            :disable-pagination="loading"
            :disable-sort="loading"
            loading-text="Oppdaterer"
          >
            <template v-slot:item.average_age="{ item }">
              <template v-if="item.average_age"> {{ item.average_age }} år</template>
            </template>
            <template v-slot:item.women="{ item }">
              <template v-if="item.women > 0">
                {{ item.women }} {{ item.women > 1 ? 'kvinner' : 'kvinne' }}</template
              >
              <template v-if="item.women > 0 && item.men > 0">, </template>
              <template v-if="item.men > 0">
                {{ item.men }} {{ item.men > 1 ? 'menn' : 'mann' }}
              </template>
              <span v-if="item.women > 0 && item.men > 0" class="caption text--secondary">
                ({{ Math.round((item.women / item.num_participants) * 100) }}% /
                {{ Math.round((item.men / item.num_participants) * 100) }}%)
              </span>
            </template>
            <template v-slot:item.valid_participations="{ item }">
              {{ item.valid_participations }}
              <span v-if="item.valid_participations > 0" class="caption text--secondary">
                ({{ Math.round((item.valid_participations / item.num_participants) * 100) }}%)
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseModel from '@/models/BaseModel'

export default {
  components: {},
  mixins: [],
  data() {
    return {
      loading: true,
      year: 2021,
      data: [],
      dataTable: {
        headers: [
          { text: 'Tittel', value: 'title' },
          { text: 'Emnekode', value: 'subject_code' },
          { text: 'Deltakere', value: 'num_participants' },
          { text: 'Gjennomsnittsalder', value: 'average_age' },
          { text: 'Kjønnsfordeling', value: 'women' },
          { text: 'Tellende deltakelser', value: 'valid_participations' },
        ],
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [1, 10, 25, 50, 100, 200],
        },
        options: {
          itemsPerPage: 10,
          page: 1,
        },
      },
    }
  },
  computed: {},
  async beforeMount() {
    try {
      const response = await BaseModel.axios.get(`/reports/${this.year}`)
      this.data = response.data
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
    this.loading = false
  },
  methods: {},
}
</script>
