<template>
  <v-card>
    <v-toolbar v-if="!coursetype" color="success" class="white--text">
      <template>
        <v-toolbar-title>Kurs</v-toolbar-title>
        <v-spacer />
        <CourseDialogButton
          v-if="!$route.params.courseteacher_id"
          color="white"
          @click="showCourseDialog()"
        />
      </template>
    </v-toolbar>
    <v-toolbar v-else :color="coursetype.color">
      <v-toolbar-title>{{ coursetype.name }}</v-toolbar-title>
      <v-spacer />
      <TooltipButton
        v-bind="createCourseButton"
        @click="showCourseDialog({ coursetype_id: coursetype.coursetype_id })"
      />
    </v-toolbar>
    <v-progress-linear v-if="loading" indeterminate />

    <v-row class="mt-2 mx-2">
      <v-col cols="12" md="6" lg="4" xl="2">
        <v-text-field
          v-model="search.course.title"
          filled
          label="Tittel"
          clearable
          :disabled="loading"
          @change="updateSearch"
        />
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="2">
        <MultiSelect
          label="Kategori"
          :items="coursecategories"
          item-text="name"
          item-value="coursecategory_id"
          multiple
          filled
          :disabled="loading"
          @change="updateSearchCategories"
        />
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="2">
        <v-text-field
          v-model="search.course.start_date.value"
          type="date"
          filled
          label="Startdato"
          hide-details
          clearable
          :disabled="loading"
          @change="updateSearch"
        />
        <v-radio-group
          v-model="search.course.start_date.compare"
          row
          :disabled="loading || !search.course.start_date.value"
          @change="updateSearch"
        >
          <v-radio label="På" value="exact"></v-radio>
          <v-radio label="Før" value="lte"></v-radio>
          <v-radio label="Etter" value="gte"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="2">
        <v-text-field
          v-model="search.course.signup_deadline.value"
          type="date"
          filled
          label="Påmeldingsfrist"
          hide-details
          clearable
          :disabled="loading"
          @change="updateSearch"
        />
        <v-radio-group
          v-model="search.course.signup_deadline.compare"
          row
          :disabled="loading || !search.course.signup_deadline.value"
          @change="updateSearch"
        >
          <v-radio label="På" value="exact"></v-radio>
          <v-radio label="Før" value="lte"></v-radio>
          <v-radio label="Etter" value="gte"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="1">
        <v-select
          v-model="selectedAvailableSpotsItem"
          :items="availableSpotsItems"
          label="Ledige plasser"
          return-object
          :disabled="loading"
          filled
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="2">
        <v-select
          v-model="selectedMinimumParticipantsItem"
          :items="minimumParticipantsItems"
          label="Oppnådd minimumsantall"
          return-object
          :disabled="loading"
          filled
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="1">
        <v-switch
          v-model="search.course.inactive"
          label="Deaktivert"
          :disabled="loading"
          @change="updateSearch"
        />
      </v-col>
    </v-row>

    <v-data-table
      v-if="courses"
      :items="courses"
      item-key="course_id"
      :loading="loading"
      :disable-pagination="loading"
      :disable-sort="loading"
      v-bind.sync="coursesTable"
      loading-text="Oppdaterer"
      no-data-text="Fant ingen kurs"
      @update:sort-desc="fetchData"
      @update:sort-by="fetchData"
      @update:page="fetchData"
      @update:items-per-page="updateItemsPerPage"
      @hook:mounted="fetchData"
    >
      <template v-slot:item.title="{ item }">
        <router-link
          :to="{
            path: `/courses/${item.course_id}`,
          }"
          style="text-decoration: none;"
        >
          {{ item.title }}<span v-if="item.location">, {{ item.location }}</span>
        </router-link>
      </template>

      <template v-slot:item.coursetype.name="{ item }">
        <v-chip
          :color="item.coursetype.color"
          @click="$router.push(`/coursetypes/${item.coursetype_id}`)"
        >
          {{ item.coursetype.name }}
        </v-chip>
      </template>

      <template v-slot:item.courseteacher.full_name="{ item }">
        <router-link
          v-if="item.courseteacher"
          :to="{
            path: `/courseteachers/${item.courseteacher_id}`,
          }"
          style="text-decoration: none;"
        >
          {{ item.courseteacher.full_name }}
        </router-link>
      </template>

      <template v-slot:item.start_date="{ item }">
        {{ item.start_date | date }}
      </template>

      <template v-slot:item.end_date="{ item }">
        {{ item.end_date | date }}
      </template>

      <template v-slot:item.signup_deadline="{ item }">
        <span
          v-if="item.signup_deadline"
          :class="isNOrFewerDaysFromNow(item.signup_deadline, 3) ? 'red--text' : ''"
        >
          {{ item.signup_deadline | date }}
        </span>
      </template>

      <template v-slot:item.participations="{ item }">
        <template v-if="item.participations.length">
          {{
            item.participations.filter(participation => {
              return participation.confirmed_date != null && participation.cancel_date === null
            }).length
          }}
        </template>
        <template v-else>0</template> /
        {{ item.max_participants }}
        <span class="caption text--secondary">(min. {{ item.min_participants }})</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <CourseDialogButton type="update" @click="showCourseDialog(item)" />
        <TooltipButton
          v-bind="deleteCourseButton"
          @click="
            getDeleteConfirmation(
              item,
              `Bekreft sletting av kurs ${item.title}`,
              `Vil du virkelig slette kurset ${item.title} for alltid?`
            )
          "
        />
      </template>
    </v-data-table>

    <EntityDialog
      ref="courseDialog"
      v-bind="courseDialog"
      @entityCreated="handleCourseCreated"
      @entityUpdated="handleCourseUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet kurset ${deleteConfirmationDialog.entityToDelete.title}`
        )
      "
    />
  </v-card>
</template>

<script>
import CourseDialogButton from '@/components/buttons/CourseDialogButton'
import TooltipButton from '@/components/buttons/TooltipButton'
import MultiSelect from '@/components/controls/MultiSelect'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import EntityDialog from '@/components/dialogs/EntityDialog'

import courseDialog from '@/mixins/dialogs/courseDialog'
import dateAndTime from '@/mixins/dateAndTime'
import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import preferences from '@/mixins/preferences.js'
import responseHelper from '@/mixins/responseHelper.js'

import Course from '@/models/Course'
import Coursecategory from '@/models/Coursecategory'
import Coursetype from '@/models/Coursetype'
import Participation from '@/models/Participation'

export default {
  components: {
    CourseDialogButton,
    DeleteConfirmationDialog,
    EntityDialog,
    MultiSelect,
    TooltipButton,
  },
  mixins: [courseDialog, dateAndTime, deleteConfirmationDialog, preferences, responseHelper],
  data() {
    return {
      loading: false,
      availableSpotsItems: [
        { text: 'Alle', filters: undefined },
        { text: 'Nei', filters: { compare: 'exact', value: 0 } },
        { text: 'Ja', filters: { compare: 'gte', value: 1 } },
      ],
      minimumParticipantsItems: [
        { text: 'Alle', filters: undefined },
        { text: 'Nei', filters: { compare: 'lt', value: 'min_participants' } },
        { text: 'Ja', filters: { compare: 'gte', value: 'min_participants' } },
      ],
    }
  },
  computed: {
    selectedAvailableSpotsItem: {
      get() {
        if (typeof this.search.course.available_spots == 'undefined')
          return this.availableSpotsItems[0]
        if (this.search.course.available_spots.value == 0) return this.availableSpotsItems[1]
        if (this.search.course.available_spots.value == 1) return this.availableSpotsItems[2]
        return null
      },
      set(val) {
        this.updateSearchAvailableSpots(val)
      },
    },
    selectedMinimumParticipantsItem: {
      get() {
        if (typeof this.search.course.minimumParticipants == 'undefined')
          return this.minimumParticipantsItems[0]
        if (this.search.course.num_participants.value == 0) return this.minimumParticipantsItems[1]
        if (this.search.course.num_participants.value == 1) return this.minimumParticipantsItems[2]
        return null
      },
      set(val) {
        this.updateSearchMinimumParticipants(val)
      },
    },
    courses() {
      let query = Course.query()
        .with('coursetype')
        .with('coursecategory')
        .with('courseteacher')
        .with('participations')

      if (this.$route.params.courseteacher_id) {
        query = query.where('courseteacher_id', parseInt(this.$route.params.courseteacher_id))
      }

      this.coursesTable.options.sortBy.forEach((sortField, i) => {
        query = query.orderBy(sortField, this.coursesTable.options.sortDesc[i] ? 'desc' : 'asc')
      })

      return query.get()
    },
    coursetype() {
      return Coursetype.query()
        .whereId(parseInt(this.$route.params.coursetype_id))
        .first()
    },
    coursecategories() {
      return Coursecategory.all()
    },
    coursesTable() {
      let headers = [
        { text: 'Tittel', value: 'title' },
        { text: 'Type', value: 'coursetype.name', sortable: false },
        { text: 'Kategori', value: 'coursecategory.name', sortable: false },
        { text: 'Startdato', value: 'start_date' },
        { text: 'Sluttdato', value: 'end_date' },
        { text: 'Påmeldingsfrist', value: 'signup_deadline' },
        { text: 'Påmeldte', value: 'participations', sortable: false },
      ]

      if (!this.$route.params.courseteacher_id) {
        headers.push(
          { text: 'Kurslærer', value: 'courseteacher.full_name', sortable: false },
          { text: '', value: 'actions', sortable: false }
        )
      }

      return {
        headers: headers,
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [10, 25, 50, 100, 200],
        },
        options: {
          itemsPerPage: this.$currentUser.preferences.components.coursestable.itemsPerPage,
          page: 1,
          sortBy: ['title'],
          sortDesc: [false],
        },
      }
    },
    search() {
      return this.$currentUser.preferences.components.coursestable.search
    },
    createCourseButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('COURSE_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COURSE_CREATE')
            ? 'Opprett kurs'
            : 'Du mangler tillatelse til å opprette kurs',
        },
      }
    },
    deleteCourseButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('COURSE_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COURSE_DELETE')
            ? 'Slett kurs'
            : 'Du mangler tillatelse til å slette kurs',
        },
      }
    },
  },
  watch: {
    $route() {
      this.fetchData()
    },
  },
  methods: {
    async fetchData() {
      if (this.loading) return

      this.loading = true

      let search = Object.assign(this.search.course)

      if (this.$route.params.coursetype_id) {
        search.coursetype_id = this.$route.params.coursetype_id
      } else {
        search.coursetype_id = undefined
      }

      const courses = await Course.fetchPaginated({
        pagination: this.coursesTable.options,
        search: search,
      })
      this.coursesTable.serverItemsLength = courses.response.data.total
      if (this.coursesTable.serverItemsLength) {
        const courseIds = this.getDistinctIdsFromEntities(courses, 'course_id')
        Participation.fetchBatchByKey('course_id', courseIds)
      }

      this.loading = false
    },
    updateItemsPerPage() {
      if (
        this.$currentUser.preferences.components.coursestable.itemsPerPage !=
        this.coursesTable.options.itemsPerPage
      ) {
        this.$currentUser.preferences.components.coursestable.itemsPerPage = this.coursesTable.options.itemsPerPage
        this.updatePreferences()
        this.fetchData()
      }
    },
    updateSearchCategories(coursecategories) {
      coursecategories = coursecategories.current.map(coursecategory => {
        return coursecategory.coursecategory_id
      })

      if (!coursecategories.length) {
        coursecategories = undefined
      }

      this.search.course.coursecategory_id = coursecategories
      this.updateSearch()
    },
    updateSearchAvailableSpots(val) {
      if (val == null) {
        this.search.course.available_spots = undefined
        return this.updateSearch()
      }

      this.search.course.available_spots = val.filters
      return this.updateSearch()
    },
    updateSearchMinimumParticipants(val) {
      if (val == null) {
        this.search.course.num_participants = undefined
        return this.updateSearch()
      }

      this.search.course.num_participants = val.filters
      return this.updateSearch()
    },
    updateSearch() {
      this.updatePreferences()
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-radio label {
  font-size: 0.8rem;
}
</style>
