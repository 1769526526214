import BaseModel from '@/models/BaseModel'

export default class Lodgingtype extends BaseModel {
  static entity = 'lodgingtypes'
  static primaryKey = 'lodgingtype_id'

  static fields() {
    return {
      lodgingtype_id: this.number(null),
      name: this.string(),
    }
  }
}
