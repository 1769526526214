<template>
  <v-card>
    <v-toolbar :color="color">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer />

      <ExportContactinfoButton
        v-if="participants.length"
        :participants="participants"
        :filename="title"
      />
    </v-toolbar>
    <v-card-text class="participants">
      <template v-if="participants.length">
        <template v-for="participant in participants">
          <router-link
            :key="participant.participant_id"
            :to="{
              path: `/participants/${participant.participant_id}`,
            }"
            >{{ participant.last_name }}, {{ participant.first_name }}</router-link
          >
          <a
            v-if="participant.email"
            :key="participant.email"
            :href="`mailto:${participant.email}`"
            :class="participant.disallows_spam ? 'red--text' : ''"
            >{{ participant.email }}</a
          >
          <span v-else :key="participant.email" />
        </template>
      </template>
      <template v-else>
        Fant ingen deltakere
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import ExportContactinfoButton from '@/components/buttons/ExportContactinfoButton'

export default {
  components: { ExportContactinfoButton },
  props: {
    color: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    participants: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.participants {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;

  a {
    text-decoration: none;
  }
}
</style>
