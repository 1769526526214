import Coursetype from '@/models/Coursetype'

export default {
  computed: {
    EXTERNAL_COURSE() {
      return Coursetype.EXTERNAL_COURSE
    },
    LOCAL_COURSE() {
      return Coursetype.LOCAL_COURSE
    },
    TRAVEL_COURSE() {
      return Coursetype.TRAVEL_COURSE
    },
  },
}
