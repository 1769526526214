<template>
  <v-dialog :max-width="maxWidth">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <TooltipButton v-bind="tooltipButton" />
      </span>
    </template>
    <v-card>
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-card-subtitle>
        <slot name="subtitle"></slot>
      </v-card-subtitle>
      <v-card-text>
        <slot name="default"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import TooltipButton from '@/components/buttons/TooltipButton'

export default {
  name: 'IconButtonDialog',
  components: { TooltipButton },
  mixins: [currentUser],
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'zoom_in',
    },
    tooltip: {
      type: String,
      default: 'Vis detaljer',
    },
    maxWidth: {
      type: Number,
      default: 450,
    },
    small: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tooltipButton() {
      return {
        button: {
          icon: this.icon,
          color: this.$currentUser.preferences.darkMode ? 'white' : '',
          small: this.small,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.tooltip,
        },
      }
    },
  },
}
</script>
