import moment from 'moment'

export default {
  methods: {
    getCurrentDate() {
      return moment().format('YYYY-MM-DD')
    },
    getCurrentTime() {
      return moment().format('hh:mm')
    },
    concatDateAndTime(dateAndTime) {
      return moment(`${dateAndTime.date} ${dateAndTime.time}`).format('YYYY-MM-DD HH:mm:ss')
    },
    isNOrFewerDaysFromNow(date, numberOfDays) {
      const cutoff = moment(date)
        .startOf('day')
        .subtract(numberOfDays, 'days')

      const today = moment().startOf('day')
      const difference = today.diff(cutoff, 'days')

      return difference >= 0 && difference <= numberOfDays
    },
  },
}
