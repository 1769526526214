<template>
  <v-container class="py-16 px-8">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <router-link :to="{ name: 'courses' }" style="text-decoration: none;">
          <v-card color="success" class="py-16">
            <v-card-title class="justify-center flex-column white--text">
              <v-icon class="mb-2 white--text" x-large>article</v-icon>
              Kurs
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <router-link :to="{ name: 'participants' }" style="text-decoration: none;">
          <v-card color="primary" class="py-16">
            <v-card-title class="justify-center flex-column white--text">
              <v-icon class="mb-2 white--text" x-large>airline_seat_recline_normal</v-icon>
              Deltakere
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <router-link :to="{ name: 'profile' }" style="text-decoration: none;">
          <v-card color="warning" class="py-16">
            <v-card-title class="justify-center flex-column white--text">
              <v-icon class="mb-2 white--text" x-large>person</v-icon>
              Din profil
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
