<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row class="mb-16">
      <v-col cols="12" md="6" lg="4">
        <v-card>
          <v-toolbar color="primary" class="white--text">
            <v-toolbar-title>{{ courseteacher.full_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <CourseteacherDialogButton
              type="update"
              color="white"
              @click="showCourseteacherDialog(courseteacher)"
            />
          </v-toolbar>
          <v-card-text :class="$currentUser.preferences.darkMode ? 'white--text' : ''">
            <dl class="mb-2">
              <template v-if="courseteacher.phone">
                <dt><v-icon>smartphone</v-icon></dt>
                <dd>
                  <a :href="`tel:${courseteacher.phone}`">{{ courseteacher.phone | phone }}</a>
                </dd>
              </template>

              <template v-if="courseteacher.email">
                <dt><v-icon>email</v-icon></dt>
                <dd>
                  <a :href="`mailto:${courseteacher.email}`">{{ courseteacher.email }}</a>
                </dd>
              </template>

              <template v-if="courseteacher.address">
                <dt><v-icon>place</v-icon></dt>
                <dd>{{ courseteacher.address }}</dd>
              </template>

              <dt><v-icon>receipt</v-icon></dt>
              <dd>Har <template v-if="!courseteacher.contract">ikke </template>arbeidsavtale</dd>

              <template v-if="courseteacher.coursecategories.length > 0">
                <dt><v-icon>school</v-icon></dt>
                <div>
                  <dd
                    v-for="coursecategory in courseteacher.coursecategories"
                    :key="coursecategory.coursecategory_id"
                  >
                    {{ coursecategory.name }}
                  </dd>
                </div>
              </template>
            </dl>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <CoursesTable />
      </v-col>
    </v-row>

    <EntityDialog
      ref="courseteacherDialog"
      v-bind="courseteacherDialog"
      @entityUpdated="handleCourseteacherUpdated"
    />
  </v-container>
</template>

<script>
import CourseteacherDialogButton from '@/components/buttons/CourseteacherDialogButton'
import CoursesTable from '@/components/datatables/CoursesTable'
import EntityDialog from '@/components/dialogs/EntityDialog'

import courseteacherDialog from '@/mixins/dialogs/courseteacherDialog.js'
import responseHelper from '@/mixins/responseHelper.js'

import Course from '@/models/Course'
import Courseteacher from '@/models/Courseteacher'

export default {
  components: { CoursesTable, CourseteacherDialogButton, EntityDialog },
  mixins: [courseteacherDialog, responseHelper],
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    courseteacher() {
      return Courseteacher.query()
        .whereId(parseInt(this.$route.params.courseteacher_id))
        .with('coursecategories')
        .first()
    },
  },
  async beforeMount() {
    try {
      await Courseteacher.fetchOneById(parseInt(this.$route.params.courseteacher_id))
      Course.fetchAll({
        search: { courseteacher_id: this.courseteacher.courseteacher_id },
      })
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
dl {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 0.5rem;
}
</style>
