import BaseModel from '@/models/BaseModel'
import Participant from '@/models/Participant'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'

export default class Coursetype extends BaseModel {
  static entity = 'coursetypes'
  static primaryKey = 'coursetype_id'

  static fields() {
    return {
      coursetype_id: this.number(null),
      name: this.string(),
      color: this.string('#2196F3'),
      participants: this.belongsToMany(
        Participant,
        ParticipantCoursetype,
        'coursetype_id',
        'participant_id'
      ),
    }
  }

  static get LOCAL_COURSE() {
    return 2
  }

  static get EXTERNAL_COURSE() {
    return 3
  }

  static get TRAVEL_COURSE() {
    return 4
  }
}
