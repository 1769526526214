<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Trafikkilder</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createTrafficsourceButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item
              v-for="trafficsource in trafficsources"
              :key="trafficsource.trafficsource_id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ trafficsource.name }}
                </v-list-item-title>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateTrafficsourceButton"
                @click="$refs.entityDialog.showDialog(model, trafficsource)"
              />

              <TooltipButton
                v-bind="deleteTrafficsourceButton"
                @click="
                  getDeleteConfirmation(
                    trafficsource,
                    `Bekreft sletting av trafikkilde ${trafficsource.name}`,
                    `Vil du virkelig slette trafikkilden ${trafficsource.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet trafikkilden ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/buttons/TooltipButton'
import Trafficsource from '@/models/Trafficsource'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data() {
    return {
      loading: false,
      model: Trafficsource,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny trafikkilde',
        editTitle: 'Rediger trafikkilde',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createTrafficsourceButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('TRAFFICSOURCE_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('TRAFFICSOURCE_CREATE')
            ? 'Opprett trafikkilde'
            : 'Du mangler tillatelse til å opprette trafikkilder',
        },
      }
    },
    updateTrafficsourceButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('TRAFFICSOURCE_UPDATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('TRAFFICSOURCE_UPDATE')
            ? 'Rediger trafikkilde'
            : 'Du mangler tillatelse til å redigere trafikkilder',
        },
      }
    },
    deleteTrafficsourceButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('TRAFFICSOURCE_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('TRAFFICSOURCE_DELETE')
            ? 'Slett trafikkilde'
            : 'Du mangler tillatelse til å slette trafikkilder',
        },
      }
    },
    trafficsources() {
      return Trafficsource.query()
        .orderBy('name')
        .get()
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Du opprettet trafikkilden ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Endringen av trafikkilden ${instance.name} er lagret`)
    },
  },
}
</script>
