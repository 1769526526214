import BaseModel from './BaseModel'
import Permission from './Permission'
import UserPermission from './UserPermission'

export default class User extends BaseModel {
  static entity = 'users'
  static primaryKey = 'user_id'

  static fields() {
    return {
      user_id: this.attr(null),
      first_name: this.string(),
      last_name: this.string(),
      phone: this.string(),
      email: this.string(),
      inactive: this.boolean(),
      preferences: this.attr(),
      permissions: this.belongsToMany(Permission, UserPermission, 'user_id', 'permission_id'),
    }
  }

  hasPermission(permission) {
    const found = this.permissions.find(element => element.name == permission)
    if (found) {
      return true
    }
  }

  static fetchAll(params, config = {}) {
    config.dataTransformer = dataTransformer
    return super.fetchAll(params, config)
  }

  static async updateOne(id, data, config = {}) {
    if (data.preferences) {
      data.preferences = JSON.stringify(data.preferences)
      config.dataTransformer = dataTransformer
    }

    return super.updateOne(id, data, config)
  }
}

function dataTransformer(response) {
  response.data.forEach(user => {
    user.preferences = JSON.parse(user.preferences)
  })
  return response.data
}
