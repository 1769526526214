<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Postnumre</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createPostalcodeButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />

          <v-row align="center" class="mt-2 mx-2">
            <v-col cols="5">
              <v-text-field
                v-model="search.postalcode.postalcode"
                filled
                label="Postnummer"
                prepend-icon="filter_alt"
                @change="fetchData"
              />
            </v-col>
            <v-col cols="7">
              <v-text-field
                v-model="search.postalcode.name"
                filled
                label="Poststed"
                @change="fetchData"
              />
            </v-col>
          </v-row>

          <v-data-iterator
            :items="postalcodes"
            item-key="postalcode_id"
            :loading="loading"
            v-bind.sync="postalcodesIterator"
            loading-text="Oppdaterer"
            @update:page="fetchData"
            @update:items-per-page="fetchData"
            @hook:mounted="fetchData"
          >
            <template v-slot:default="{ items, isExpanded, expand }">
              <v-list>
                <v-list-item v-for="postalcode in items" :key="postalcode.postalcode_id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ postalcode.postalcode }} {{ postalcode.name }}
                      <span class="caption font-weight-thin text--secondary text-uppercase">
                        {{ postalcode.municipality.name }} kommune,
                        {{ postalcode.municipality.county.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>

                  <TooltipButton
                    v-bind="updatePostalcodeButton"
                    @click="$refs.entityDialog.showDialog(model, postalcode)"
                  />

                  <TooltipButton
                    v-bind="deletePostalcodeButton"
                    @click="
                      getDeleteConfirmation(
                        postalcode,
                        `Bekreft sletting av postnummer ${postalcode.name}`,
                        `Vil du virkelig slette postnummeret ${postalcode.name} for alltid?`
                      )
                    "
                  />
                </v-list-item>
              </v-list>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet postnummeret ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/buttons/TooltipButton'
import Municipality from '@/models/Municipality'
import Postalcode from '@/models/Postalcode'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data() {
    return {
      loading: false,
      model: Postalcode,
      postalcodesIterator: {
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [10, 20, 40, 100],
        },
        options: {
          itemsPerPage: 20,
          page: 1,
        },
      },
      search: {
        postalcode: {
          postalcode: '',
          name: '',
        },
      },
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny postnummer',
        editTitle: 'Rediger postnummer',
        fields: [
          {
            name: 'postalcode',
            type: 'text',
            label: 'Postnummer',
            rules: [this.$validationRules.required],
          },
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'municipality_id',
            type: 'select', //TODO need to make an autocomplete control
            label: 'Kommune',
            items: Municipality.query()
              .orderBy('name')
              .get(),
            itemText: 'name',
            itemValue: 'municipality_id',
            noDataText: this.$currentUser.hasPermission('MUNICIPALITY_CREATE')
              ? 'Du må opprette ny kommune under Administrasjon > Kommuner.'
              : 'Ny kommune må opprettes av en administrator.',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createPostalcodeButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('POSTALCODE_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('POSTALCODE_CREATE')
            ? 'Opprett postnummer'
            : 'Du mangler tillatelse til å opprette postnumre',
        },
      }
    },
    updatePostalcodeButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('POSTALCODE_UPDATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('POSTALCODE_UPDATE')
            ? 'Rediger postnummer'
            : 'Du mangler tillatelse til å redigere postnumre',
        },
      }
    },
    deletePostalcodeButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('POSTALCODE_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('POSTALCODE_DELETE')
            ? 'Slett postnummer'
            : 'Du mangler tillatelse til å slette postnumre',
        },
      }
    },
    postalcodes() {
      return Postalcode.query()
        .with('municipality')
        .with('municipality.county')
        .orderBy('postalcode')
        .get()
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Du opprettet postnummeret ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Endringen av postnummeret ${instance.name} er lagret`)
    },
    async fetchData() {
      this.loading = true

      const postalcodes = await Postalcode.fetchPaginated({
        pagination: this.postalcodesIterator.options,
        sortBy: 'postalcode',
        sortDesc: false,
        search:
          this.search.postalcode.postalcode || this.search.postalcode.name
            ? this.search.postalcode
            : null,
      })
      this.postalcodesIterator.serverItemsLength = postalcodes.response.data.total

      this.loading = false
    },
  },
}
</script>
