import BaseModel from '@/models/BaseModel'

export default class Communicationtype extends BaseModel {
  static entity = 'communicationtypes'
  static primaryKey = 'communicationtype_id'

  static fields() {
    return {
      communicationtype_id: this.number(null),
      name: this.string(),
      icon: this.string(),
    }
  }
}
