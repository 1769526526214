export default {
  methods: {
    getAddedEntities(newEntities, oldEntities) {
      return newEntities.filter(({ $id: id1 }) => !oldEntities.some(({ $id: id2 }) => id2 === id1))
    },

    getRemovedEntities(newEntities, oldEntities) {
      return oldEntities.filter(({ $id: id1 }) => !newEntities.some(({ $id: id2 }) => id2 === id1))
    },

    async createAddedAdvancedRelatedEntities(newEntities, oldEntities, model, pivotModel) {
      if (!newEntities || !oldEntities) {
        return
      }

      const addedEntities = this.getAddedEntities(newEntities, oldEntities)

      addedEntities.forEach(async addedEntity => {
        this.loading = true
        try {
          const pivotData = { ...this.entity.primaryKey.key, ...addedEntity.primaryKey.key }
          addedEntity.pivot = await pivotModel.createOne(pivotData)
        } catch (e) {
          this.handleError(e.message)
        } finally {
          this.loading = false
        }
      })
    },

    async createAddedSimpleRelatedEntities(newEntities, oldEntities, model, pivotModel) {
      if (!newEntities || !oldEntities) {
        return
      }

      this.getAddedEntities(newEntities, oldEntities).forEach(async addedEntity => {
        try {
          this.loading = true

          const relatedEntity = await model.createOne(addedEntity)

          relatedEntity.pivot = await pivotModel.createOne({
            ...relatedEntity.primaryKey.key,
            ...this.entity.primaryKey.key,
          })

          //replace the "text only" entity with the properly created one
          const index = this.entity[model.entity].indexOf(addedEntity)
          this.entity[model.entity][index] = relatedEntity
        } catch (e) {
          this.handleError(`${e.message} - endringen ble ikke lagret`)
        } finally {
          this.loading = false
        }
      })
    },

    async removeRelatedEntities(newEntities, oldEntities, deleteRelatedEntity = false) {
      if (!newEntities || !oldEntities) {
        return
      }

      this.getRemovedEntities(newEntities, oldEntities).forEach(async removedEntity => {
        this.loading = true
        try {
          await removedEntity.pivot.delete()

          if (deleteRelatedEntity) {
            await removedEntity.delete()
          }
        } catch (e) {
          if (e.message == 'ER_ROW_IS_REFERENCED_2') {
            //the entity is used elsewhere, do nothing
          } else {
            this.handleError([`Det oppsto en feil: ${e.message}`, 'Endringen ble ikke lagret.'])
          }
        } finally {
          this.loading = false
        }
      })
    },

    async createOrUpdateEntity(model, data, id) {
      if (this.loading) {
        return
      }

      this.loading = true

      try {
        let newEntity = null

        if (id) {
          newEntity = await model.updateOne(id, data)
          this.$emit('entityUpdated', newEntity)
        } else {
          newEntity = await model.createOne(data)
          this.$emit('entityCreated', newEntity)
        }

        this.loading = false
        return newEntity
      } catch (e) {
        this.handleError(['Det oppsto en feil:', e.message, 'Endringen ble ikke lagret.'])
      }
    },
  },
}
