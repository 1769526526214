<template>
  <nav>
    <v-toolbar color="primary" dark>
      <v-toolbar-title class="headline">
        <span>NNPS </span>
        <span class="font-weight-light">deltakersystem</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items v-if="isAuthenticated">
        <v-btn :to="{ name: 'home' }" text exact>Hjem</v-btn>

        <v-btn :to="{ name: 'courses' }" text exact>Kurs</v-btn>

        <v-btn :to="{ name: 'participants' }" text exact>Deltakere</v-btn>

        <v-btn :to="{ name: 'courseteachers' }" text exact>Kurslærere</v-btn>

        <v-menu close-on-content-click offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">Administrasjon</v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                if ($route.name !== 'statistics') {
                  $router.push({ name: 'statistics' })
                }
              "
            >
              <v-list-item-title>Kursstatistikk</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'users') {
                  $router.push({ name: 'users' })
                }
              "
            >
              <v-list-item-title>Brukere</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'permissions') {
                  $router.push({ name: 'permissions' })
                }
              "
            >
              <v-list-item-title>Tillatelser</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'postalcodes') {
                  $router.push({ name: 'postalcodes' })
                }
              "
            >
              <v-list-item-title>Postnumre</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'municipalities') {
                  $router.push({ name: 'municipalities' })
                }
              "
            >
              <v-list-item-title>Kommuner</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'counties') {
                  $router.push({ name: 'counties' })
                }
              "
            >
              <v-list-item-title>Fylker</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'coursetypes') {
                  $router.push({ name: 'coursetypes' })
                }
              "
            >
              <v-list-item-title>Kurstyper</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'coursecategories') {
                  $router.push({ name: 'coursecategories' })
                }
              "
            >
              <v-list-item-title>Kurskategorier</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'trafficsources') {
                  $router.push({ name: 'trafficsources' })
                }
              "
            >
              <v-list-item-title>Trafikkilder</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'communicationtypes') {
                  $router.push({ name: 'communicationtypes' })
                }
              "
            >
              <v-list-item-title>Kommunikasjonstyper</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'communicationcategories') {
                  $router.push({ name: 'communicationcategories' })
                }
              "
            >
              <v-list-item-title>Kommunikasjonskategorier</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                if ($route.name !== 'lodgingtypes') {
                  $router.push({ name: 'lodgingtypes' })
                }
              "
            >
              <v-list-item-title>Innlosjeringstyper</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-toolbar-items v-if="isAuthenticated">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon :to="{ name: 'profile' }" v-on="on">
              <v-icon>person</v-icon>
            </v-btn>
          </template>
          <span>Din profil</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="logout" v-on="on">
              <v-icon>exit_to_app</v-icon>
            </v-btn></template
          >
          <span>Logg ut</span>
        </v-tooltip>
      </v-toolbar-items>

      <v-toolbar-items v-if="!isAuthenticated">
        <v-btn text @click="login">
          <span class="mr-2">Logg inn</span>
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false,
      showAdminDropdown: false,
    }
  },
  methods: {
    login() {
      this.$auth.login()
    },
    logout() {
      this.$auth.logOut()
    },
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn
    },
  },
}
</script>
