export default {
  computed: {
    $validationRules() {
      return {
        positive: value => {
          if (!value) return true
          return value > 0 || `Må være større enn 0`
        },
        required: value => !!value || 'Påkrevd',
        email: value => {
          if (!value) return true

          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ugyldig epostadresse'
        },
        url: value => {
          if (!value) return true
          const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$',
            'i'
          ) // fragment locator
          return !!pattern.test(value) || 'Ugyldig URL'
        },
      }
    },
  },
}
