import Courseteacher from '@/models/Courseteacher'
import Coursecategory from '@/models/Coursecategory'
import CourseteacherCoursecategory from '@/models/CourseteacherCoursecategory'

export default {
  data() {
    return {
      selectedCourseteacher: null,
    }
  },
  computed: {
    courseteacherDialog() {
      return {
        createTitle: 'Opprett ny kurslærer',
        editTitle: 'Rediger kurslærer',
        fields: [
          {
            name: 'first_name',
            type: 'text',
            label: 'Fornavn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'last_name',
            type: 'text',
            label: 'Etternavn',
            rules: [this.$validationRules.required],
          },
          {
            name: 'email',
            type: 'text',
            label: 'Epost',
            rules: [this.$validationRules.email],
          },
          {
            name: 'phone',
            type: 'text',
            label: 'Telefon',
          },
          {
            name: 'address',
            type: 'text',
            label: 'Adresse',
          },
          {
            name: 'coursecategories',
            type: 'multiselect',
            label: 'Kurskategorier',
            items: [...Coursecategory.all()],
            itemText: 'name',
            itemValue: 'coursecategory_id',
            changeHandler: this.handleCourseteacherCoursecategoriesChange,
          },
          {
            name: 'contract',
            type: 'switch',
            label: 'Har arbeidsavtale',
          },
          {
            name: 'inactive',
            type: 'switch',
            label: 'Inaktiv',
          },
        ],
      }
    },
  },
  methods: {
    showCourseteacherDialog(courseteacher) {
      this.selectedCourseteacher = courseteacher
      this.$refs.courseteacherDialog.showDialog(Courseteacher, courseteacher)
    },
    handleCourseteacherCreated(courseteacher) {
      this.selectedCourseteacher = courseteacher
      this.$refs.courseteacherDialog.entity = courseteacher
      this.$successToast(`Du opprettet kurslæreren ${courseteacher.full_name}`)
    },
    handleCourseteacherUpdated(courseteacher) {
      this.$refs.courseteacherDialog.entity = Courseteacher.query()
        .whereId(courseteacher.courseteacher_id)
        .first()

      this.$successToast(`Endringen av kurslæreren ${courseteacher.full_name} er lagret`)
    },
    handleCourseteacherCoursecategoriesChange(field, changes) {
      for (const coursecategory of changes.added) {
        CourseteacherCoursecategory.createOne({
          courseteacher_id: this.selectedCourseteacher.courseteacher_id,
          coursecategory_id: coursecategory.coursecategory_id,
        })
      }

      for (const coursecategory of changes.removed) {
        CourseteacherCoursecategory.deleteOne({
          courseteacher_id: this.selectedCourseteacher.courseteacher_id,
          coursecategory_id: coursecategory.coursecategory_id,
        })
      }
    },
  },
}
