import BaseModel from '@/models/BaseModel'
import County from '@/models/County'

export default class Municipality extends BaseModel {
  static entity = 'municipalities'
  static primaryKey = 'municipality_id'

  static fields() {
    return {
      municipality_id: this.number(null),
      county_id: this.number(),
      name: this.string(),
      county: this.belongsTo(County, 'county_id'),
    }
  }
}
