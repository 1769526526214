import BaseModel from './BaseModel'

export default class CourseteacherCoursecategory extends BaseModel {
  static entity = 'courseteachers_coursecategories'

  static primaryKey = ['courseteacher_id', 'coursecategory_id']

  static fields() {
    return {
      courseteacher_id: this.attr(null),
      coursecategory_id: this.attr(null),
    }
  }
}
