import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth/authentication'
import Callback from './components/Callback'

import Home from '@/views/Home.vue'
import Communicationtypes from '@/views/Communicationtypes.vue'
import Communicationcategories from '@/views/Communicationcategories.vue'
import Counties from '@/views/Counties.vue'
import Coursecategories from '@/views/Coursecategories.vue'
import Course from '@/views/Course.vue'
import Courseteacher from '@/views/Courseteacher.vue'
import Courseteachers from '@/views/Courseteachers.vue'
import Courses from '@/views/Courses.vue'
import Coursetypes from '@/views/Coursetypes.vue'
import Lodgingtypes from '@/views/Lodgingtypes.vue'
import Municipalities from '@/views/Municipalities.vue'
import Participant from '@/views/Participant.vue'
import Participants from '@/views/Participants.vue'
import Permissions from '@/views/Permissions.vue'
import Postalcodes from '@/views/Postalcodes.vue'
import PotentialParticipants from '@/views/PotentialParticipants.vue'
import Profile from '@/views/Profile.vue'
import Statistics from '@/views/Statistics.vue'
import Trafficsources from '@/views/Trafficsources.vue'
import Users from '@/views/Users.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/callback', //callback url for auth0 token (re-)validation
      name: 'callback',
      component: Callback,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/communicationcategories',
      name: 'communicationcategories',
      component: Communicationcategories,
    },
    {
      path: '/communicationtypes',
      name: 'communicationtypes',
      component: Communicationtypes,
    },
    {
      path: '/counties',
      name: 'counties',
      component: Counties,
    },
    {
      path: '/coursecategories',
      name: 'coursecategories',
      component: Coursecategories,
    },
    {
      path: '/courseteachers',
      name: 'courseteachers',
      component: Courseteachers,
    },
    {
      path: '/courseteachers/:courseteacher_id',
      name: 'courseteacher',
      component: Courseteacher,
    },
    {
      path: '/courses',
      name: 'courses',
      component: Courses,
    },
    {
      path: '/courses/:course_id',
      name: 'course',
      component: Course,
    },
    {
      path: '/courses/:course_id/potential-participants',
      name: 'potentialParticipants',
      component: PotentialParticipants,
    },
    {
      path: '/coursetypes/:coursetype_id',
      name: 'coursetype',
      component: Courses,
    },
    {
      path: '/coursetypes',
      name: 'coursetypes',
      component: Coursetypes,
    },
    {
      path: '/lodgingtypes',
      name: 'lodgingtypes',
      component: Lodgingtypes,
    },
    {
      path: '/municipalities',
      name: 'municipalities',
      component: Municipalities,
    },
    {
      path: '/participants/:participant_id',
      name: 'participant',
      component: Participant,
    },
    {
      path: '/participants',
      name: 'participants',
      component: Participants,
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: Permissions,
    },
    {
      path: '/postalcodes',
      name: 'postalcodes',
      component: Postalcodes,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: Statistics,
    },
    {
      path: '/trafficsources',
      name: 'trafficsources',
      component: Trafficsources,
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
  ],
})

//redirect all url's except home and login callback page for users that are not logged in
router.beforeEach((to, _from, next) => {
  if (to.path === '/' || to.path === '/callback' || auth.isAuthenticated()) {
    return next()
  }
  auth.login({ target: to.path })
})

export default router
