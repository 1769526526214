import BaseModel from './BaseModel'

export default class Gender extends BaseModel {
  static entity = 'genders'
  static primaryKey = 'gender_id'

  static fields() {
    return {
      gender_id: this.attr(null),
      name: this.string(),
    }
  }
}
