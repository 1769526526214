import BaseModel from '@/models/BaseModel'
import Coursetype from '@/models/Coursetype'
import Gender from '@/models/Gender'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'
import Participation from '@/models/Participation'
import Postalcode from '@/models/Postalcode'

export default class Participant extends BaseModel {
  static entity = 'participants'
  static primaryKey = 'participant_id'

  static fields() {
    return {
      participant_id: this.number(null),
      postalcode_id: this.number(null).nullable(),
      gender_id: this.number(null),
      first_name: this.string(),
      last_name: this.string(),
      full_name: this.string(),
      birthdate: this.string().nullable(),
      phone: this.string().nullable(),
      email: this.string().nullable(),
      address: this.string().nullable(),
      important_info: this.string().nullable(),
      disallows_photos: this.boolean(),
      disallows_spam: this.boolean(),
      deceased: this.boolean(),
      participations: this.hasMany(Participation, 'participant_id', 'participant_id'),
      postalcode: this.belongsTo(Postalcode, 'postalcode_id'),
      gender: this.belongsTo(Gender, 'gender_id'),
      coursetypes: this.belongsToMany(
        Coursetype,
        ParticipantCoursetype,
        'participant_id',
        'coursetype_id'
      ),
    }
  }
}
