import axios from 'axios'

import BaseModel from '@/models/BaseModel'
import Courseteacher from '@/models/Courseteacher'
import Coursetype from '@/models/Coursetype'
import Coursecategory from '@/models/Coursecategory'
import Participation from '@/models/Participation'

const COURSETYPE_TRAVEL = 4
const COURSETYPE_EXTERNAL = 3
const TRAVEL_COURSE_URL = 'travel_courses'
const EXTERNAL_COURSE_URL = 'external_courses'
const TRAVEL_COURSE_FIELDS = ['reunion_date']
const EXTERNAL_COURSE_FIELDS = ['location']

export default class Course extends BaseModel {
  static entity = 'courses'
  static primaryKey = 'course_id'

  static fields() {
    return {
      course_id: this.number(null),
      coursetype_id: this.number(null),
      coursecategory_id: this.number(null),
      courseteacher_id: this.number(null),
      subject_code: this.string().nullable(),
      title: this.string(),
      location: this.string().nullable(),
      start_date: this.string().nullable(),
      end_date: this.string().nullable(),
      reunion_date: this.string().nullable(),
      signup_deadline: this.string().nullable(),
      description_link: this.string().nullable(),
      min_participants: this.number(),
      max_participants: this.number(),
      price: this.number().nullable(),
      hours_total: this.number().nullable(),
      comment: this.string().nullable(),
      inactive: this.boolean(),
      coursetype: this.belongsTo(Coursetype, 'coursetype_id'),
      coursecategory: this.belongsTo(Coursecategory, 'coursecategory_id'),
      courseteacher: this.belongsTo(Courseteacher, 'courseteacher_id'),
      participations: this.hasMany(Participation, 'course_id'),
    }
  }

  static async fetchAll(params, config = {}) {
    config.dataTransformer = fetchChildren
    return await super.fetchAll(params, config)
  }

  static async fetchBatch(ids, deleteAll = true, config = {}) {
    config.dataTransformer = fetchChildren
    return await super.fetchBatch(ids, deleteAll, config)
  }

  static fetchPaginated({ search, pagination, deleteAll = true } = {}, config = {}) {
    config.dataTransformer = fetchChildren
    return super.fetchPaginated({ search, pagination, deleteAll }, config)
  }

  static fetchOneById(id, deleteAll = false, config = {}) {
    config.dataTransformer = fetchChildren
    return super.fetchOneById(id, deleteAll, config)
  }

  static async updateOne(id, data = {}, config = {}) {
    config.dataTransformer = fetchChildren

    const courseTypeId = Course.find(id).coursetype_id

    if (courseTypeId == COURSETYPE_EXTERNAL) {
      const childData = {}
      EXTERNAL_COURSE_FIELDS.forEach(field => {
        if (field in data) {
          childData[field] = data[field]
          delete data[field]
        }
      })

      if (Object.keys(childData).length > 0) {
        const childResponse = await axios.put(`${EXTERNAL_COURSE_URL}/${id}`, childData)
        if (childResponse.data.length) {
          updateChildFieldsInStore(childResponse.data[0])
        }
      }
    } else if (courseTypeId == COURSETYPE_TRAVEL) {
      const childData = {}
      TRAVEL_COURSE_FIELDS.forEach(field => {
        if (field in data) {
          childData[field] = data[field]
          delete data[field]
        }
      })

      if (Object.keys(childData).length > 0) {
        const childResponse = await axios.put(`${TRAVEL_COURSE_URL}/${id}`, childData)
        if (childResponse.data.length) {
          updateChildFieldsInStore(childResponse.data[0])
        }
      }
    }

    return await super.updateOne(id, data, config)
  }
}

function fetchChildren(response) {
  const travelCourseIds = []
  const externalCourseIds = []

  let courses = Array.isArray(response.data) ? response.data : response.data.data

  courses.forEach(course => {
    if (course.coursetype_id == COURSETYPE_TRAVEL) {
      travelCourseIds.push(course.course_id)
    } else if (course.coursetype_id == COURSETYPE_EXTERNAL) {
      externalCourseIds.push(course.course_id)
    }
  })

  if (travelCourseIds.length) {
    axios.get(`${TRAVEL_COURSE_URL}?ids=${travelCourseIds.toString()}`).then(response => {
      response.data.forEach(updateChildFieldsInStore)
    })
  }

  if (externalCourseIds.length) {
    axios.get(`${EXTERNAL_COURSE_URL}?ids=${externalCourseIds.toString()}`).then(response => {
      response.data.forEach(child => {
        updateChildFieldsInStore(child)
      })
    })
  }

  return Array.isArray(response.data) ? response.data : response.data.data
}

function updateChildFieldsInStore(child) {
  Course.update({
    where: child.course_id,
    data: { ...child },
  })
}
