var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$currentUser)?_c('v-container',{staticClass:"py-16 px-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6","offset-md":"2","offset-lg":"3"}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"success"}},[_c('v-toolbar-title',[_vm._v("Postnumre")]),_c('v-spacer'),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.$refs.entityDialog.showDialog(_vm.model)}}},'TooltipButton',_vm.createPostalcodeButton,false))],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-row',{staticClass:"mt-2 mx-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"filled":"","label":"Postnummer","prepend-icon":"filter_alt"},on:{"change":_vm.fetchData},model:{value:(_vm.search.postalcode.postalcode),callback:function ($$v) {_vm.$set(_vm.search.postalcode, "postalcode", $$v)},expression:"search.postalcode.postalcode"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"filled":"","label":"Poststed"},on:{"change":_vm.fetchData},model:{value:(_vm.search.postalcode.name),callback:function ($$v) {_vm.$set(_vm.search.postalcode, "name", $$v)},expression:"search.postalcode.name"}})],1)],1),_c('v-data-iterator',_vm._b({attrs:{"items":_vm.postalcodes,"item-key":"postalcode_id","loading":_vm.loading,"loading-text":"Oppdaterer"},on:{"update:page":_vm.fetchData,"update:items-per-page":_vm.fetchData,"hook:mounted":_vm.fetchData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-list',_vm._l((items),function(postalcode){return _c('v-list-item',{key:postalcode.postalcode_id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(postalcode.postalcode)+" "+_vm._s(postalcode.name)+" "),_c('span',{staticClass:"caption font-weight-thin text--secondary text-uppercase"},[_vm._v(" "+_vm._s(postalcode.municipality.name)+" kommune, "+_vm._s(postalcode.municipality.county.name)+" ")])])],1),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.$refs.entityDialog.showDialog(_vm.model, postalcode)}}},'TooltipButton',_vm.updatePostalcodeButton,false)),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.getDeleteConfirmation(
                      postalcode,
                      ("Bekreft sletting av postnummer " + (postalcode.name)),
                      ("Vil du virkelig slette postnummeret " + (postalcode.name) + " for alltid?")
                    )}}},'TooltipButton',_vm.deletePostalcodeButton,false))],1)}),1)]}}],null,false,1722026461)},'v-data-iterator',_vm.postalcodesIterator,false,true))],1)],1)],1),_c('EntityDialog',_vm._b({ref:"entityDialog",on:{"entityCreated":_vm.handleEntityCreated,"entityUpdated":_vm.handleEntityUpdated}},'EntityDialog',_vm.entityDialog,false)),_c('DeleteConfirmationDialog',_vm._b({ref:"deleteConfirmationDialog",on:{"cancel":_vm.closeAndResetDeleteConfirmationDialog,"success":function($event){return _vm.closeAndResetDeleteConfirmationDialog(
        ("Du slettet postnummeret " + (_vm.deleteConfirmationDialog.entityToDelete.name))
      )}}},'DeleteConfirmationDialog',_vm.deleteConfirmationDialog,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }