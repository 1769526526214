import User from '@/models/User'

export default {
  computed: {
    $currentUser() {
      return User.query()
        .with('permissions')
        .where('email', this.$auth.profile.email)
        .first()
    },
  },
}
