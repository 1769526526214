<template>
  <v-container v-if="$currentUser" class="py-16 px-0">
    <v-row class="mb-16">
      <v-col cols="12" sm="6" md="5" lg="6" xl="4">
        <v-card height="100%">
          <v-toolbar :color="course.coursetype.color" class="white--text">
            <v-toolbar-title>{{ course.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <CourseDialogButton type="update" color="white" @click="showCourseDialog(course)" />
          </v-toolbar>

          <v-card-text :class="$currentUser.preferences.darkMode ? 'white--text' : ''">
            <dl class="mb-2">
              <template v-if="course.location">
                <dt>Kurssted</dt>
                <dd>{{ course.location }}</dd>
              </template>

              <dt>Kategori</dt>
              <dd>{{ course.coursecategory.name }}</dd>

              <template v-if="course.courseteacher">
                <dt>Kurslærer</dt>
                <dd>
                  <router-link
                    :to="{
                      path: `/courseteachers/${course.courseteacher_id}`,
                    }"
                    style="text-decoration: none;"
                  >
                    {{ course.courseteacher.full_name }}
                  </router-link>
                </dd>
              </template>

              <template v-if="course.subject_code">
                <dt>Emnekode</dt>
                <dd>{{ course.subject_code }}</dd>
              </template>

              <template v-if="course.start_date">
                <dt>Dato</dt>
                <dd>
                  {{ course.start_date | date }}
                  <template v-if="course.end_date"> - {{ course.end_date | date }}</template>
                </dd>
              </template>

              <template v-if="course.reunion_date">
                <dt>Ettersamling</dt>
                <dd>{{ course.reunion_date | date }}</dd>
              </template>

              <template v-if="course.price">
                <dt>Pris</dt>
                <dd>{{ course.price | currency }}</dd>
              </template>

              <template v-if="course.hours_total">
                <dt>Antall timer</dt>
                <dd>{{ course.hours_total }}</dd>
              </template>

              <dt>Påmeldte</dt>
              <dd>
                {{ confirmedParticipations.length }} / {{ course.max_participants }}
                <span class="caption">(minimum {{ course.min_participants }})</span>
              </dd>

              <dt>Venteliste</dt>
              <dd>
                {{ signedUpParticipations.length }}
              </dd>

              <dt>Interesserte</dt>
              <dd>
                {{ interestedParticipations.length }}
              </dd>

              <dt>Avmeldte</dt>
              <dd>
                {{ cancelledParticipations.length }}
              </dd>

              <template v-if="course.comment">
                <dt>Kommentar</dt>
                <dd v-linkified>{{ course.comment }}</dd>
                <!-- 
                  TODO:
                  eslint-disable-next-line 
                  <dd v-linkified v-html="course.comment"></dd>
                -->
              </template>
            </dl>

            <a v-if="course.description_link" :href="course.description_link" target="_blank">
              Kursbeskrivelse
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="7" lg="6" xl="8">
        <v-row>
          <v-col cols="12" md="6">
            <router-link
              :to="{
                path: `/courses/${course.course_id}/potential-participants`,
              }"
              style="text-decoration: none;"
            >
              <v-card color="success" class="py-8 text-center">
                <v-icon class="mb-2 white--text" x-large>person_search</v-icon>
                <v-card-title height="100%" class="white--text justify-center pt-0">
                  Potensielle deltakere
                </v-card-title>
              </v-card>
            </router-link>
          </v-col>
          <!--TODO disable buttons when there are no participants -->
          <v-col cols="12" md="6">
            <v-card color="primary" class="py-8 text-center" @click="generateNametagPdf">
              <v-icon class="mb-2 white--text" x-large>border_all</v-icon>
              <v-card-title height="100%" class="white--text justify-center pt-0">
                Navnelapper
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card color="primary" class="py-8 text-center" @click="generateParticipantListPdf">
              <v-icon class="mb-2 white--text" x-large>list</v-icon>
              <v-card-title height="100%" class="white--text justify-center pt-0">
                Deltakerliste
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card color="primary" class="py-8 text-center" @click="generateShippingLabelPdf">
              <v-icon class="mb-2 white--text" x-large>sticky_note_2</v-icon>
              <v-card-title height="100%" class="white--text justify-center pt-0">
                Adresseetiketter
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="course"
          participation-type="confirmed"
          :participations="confirmedParticipations"
          :loading="loading"
          :course="course"
        />
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="course"
          participation-type="signedUp"
          :participations="signedUpParticipations"
          :loading="loading"
          :course="course"
        />
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="course"
          participation-type="interested"
          :participations="interestedParticipations"
          :loading="loading"
          :course="course"
        />
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="course"
          participation-type="cancelled"
          :participations="cancelledParticipations"
          :loading="loading"
          :course="course"
        />
      </v-col>
    </v-row>

    <v-row v-if="blankParticipations.length" class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="course"
          participation-type="blank"
          :participations="blankParticipations"
          :loading="loading"
          :course="course"
        />
      </v-col>
    </v-row>

    <EntityDialog ref="courseDialog" v-bind="courseDialog" @entityUpdated="handleCourseUpdated" />

    <vue-html2pdf
      ref="nametags"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="`Navneskilt - ${course.title}`"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="841.9pt"
    >
      <section slot="pdf-content">
        <div
          v-for="(participationGroup, i) in nametagGroups"
          :key="participationGroup[0].participation_id"
          class="nametags"
        >
          <div
            v-for="participation in participationGroup"
            :key="participation.participation_id"
            class="nametag"
          >
            <span v-if="participation.participant">{{ participation.participant.first_name }}</span>
          </div>
          <div v-if="i != nametagGroups.length - 1" class="html2pdf__page-break" />
        </div>
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      ref="participantList"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="`Deltakerliste - ${course.title}`"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
    >
      <section slot="pdf-content">
        <div class="participants">
          <h2 class="participants__title">
            Deltakerliste
            {{ course.title }}, {{ course.start_date | dayAndMonth }}-{{ course.end_date | date }}
          </h2>
          <div
            v-for="(participationGroup, i) in participantListGroups"
            :key="participationGroup[0].participation_id"
          >
            <table class="participants__list">
              <thead>
                <tr>
                  <th>Navn</th>
                  <th>Adresse</th>
                  <th>Telefon</th>
                  <th>Fravær</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="participation in participationGroup"
                  :key="participation.participation_id"
                  class="participant"
                >
                  <td>
                    <template v-if="participation.participant">
                      {{ participation.participant.last_name }},
                      {{ participation.participant.first_name
                      }}<span v-if="participation.participant.deceased" style="color: red;">
                        (AVDØD)</span
                      >
                    </template>
                  </td>
                  <td>
                    <template v-if="participation.participant">
                      {{ participation.participant.address }}
                      <template
                        v-if="
                          participation.participant.postalcode && participation.participant.address
                        "
                        >,
                      </template>
                      <template v-if="participation.participant.postalcode">
                        {{ participation.participant.postalcode.postalcode }}
                        {{ participation.participant.postalcode.name }}
                      </template>
                    </template>
                  </td>
                  <td class="participants__phone">
                    <template v-if="participation.participant">
                      {{ participation.participant.phone | phone }}
                    </template>
                  </td>
                  <td class="participants__missed_hours"></td>
                </tr>
              </tbody>
            </table>
            <div v-if="i != participantListGroups.length - 1" class="html2pdf__page-break" />
          </div>
          <div class="participants__signatures">
            <span class="participants__signature">Dato, underskrift</span>
            <span class="participants__signature">Dato, underskrift</span>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      ref="shippingLabels"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="`Adresseetiketter - ${course.title}`"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
    >
      <section slot="pdf-content">
        <div
          v-for="(participationGroup, i) in shippingLabelGroups"
          :key="participationGroup[0].participation_id"
          class="shippingLabels"
        >
          <div
            v-for="participation in participationGroup"
            :key="participation.participation_id"
            class="shippingLabel"
          >
            <div v-if="participation.participant">
              <p>
                {{ participation.participant.first_name }} {{ participation.participant.last_name }}
              </p>
              <p>
                {{ participation.participant.address }}
                <span v-if="participation.participant.postalcode">
                  <br />
                  {{ participation.participant.postalcode.postalcode }}
                  {{ participation.participant.postalcode.name }}
                </span>
              </p>
            </div>
          </div>
          <div v-if="i != shippingLabelGroups.length - 1" class="html2pdf__page-break" />
        </div>
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
//todo why doesnt toasting work for participations and communications??

import VueHtml2pdf from 'vue-html2pdf'

import CourseDialogButton from '@/components/buttons/CourseDialogButton'
import ParticipationTable from '@/components/datatables/ParticipationTable'
import EntityDialog from '@/components/dialogs/EntityDialog'

import courseDialog from '@/mixins/dialogs/courseDialog'
import participations from '@/mixins/participations.js'
import responseHelper from '@/mixins/responseHelper.js'

import Communication from '@/models/Communication'
import Course from '@/models/Course'
import Participant from '@/models/Participant'
import Participation from '@/models/Participation'
import Postalcode from '@/models/Postalcode'

export default {
  components: { EntityDialog, CourseDialogButton, ParticipationTable, VueHtml2pdf },
  mixins: [courseDialog, participations, responseHelper],
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    course() {
      return Course.query()
        .whereId(parseInt(this.$route.params.course_id))
        .with('coursetype')
        .with('coursecategory')
        .with('courseteacher')
        .first()
    },
    nametagGroups() {
      const perPage = 14
      let nametagGroups = []

      const participants = this.getLivingConfirmedParticipants()

      for (let i = 0; i < participants.length; i += perPage) {
        nametagGroups.push(participants.slice(i, i + perPage))
      }
      return nametagGroups
    },
    shippingLabelGroups() {
      const perPage = 16
      let shippingLabelGroups = []

      const participants = this.getLivingConfirmedParticipants()

      for (let i = 0; i < participants.length; i += perPage) {
        shippingLabelGroups.push(participants.slice(i, i + perPage))
      }
      return shippingLabelGroups
    },
    participantListGroups() {
      const perPage = 20
      let participantListGroups = []

      const confirmedParticipationsSorted = [...this.confirmedParticipations].sort((a, b) => {
        if (a.participant?.last_name < b.participant?.last_name) {
          return -1
        }
        if (a.participant?.last_name > b.participant?.last_name) {
          return 1
        }
        return 0
      })

      for (let i = 0; i < confirmedParticipationsSorted.length; i += perPage) {
        participantListGroups.push(confirmedParticipationsSorted.slice(i, i + perPage))
      }
      return participantListGroups
    },
  },
  async beforeMount() {
    try {
      const course = await Course.fetchOneById(parseInt(this.$route.params.course_id))

      const participations = await Participation.fetchAll({
        search: { course_id: course.course_id },
      })

      const participationIds = this.getDistinctIdsFromEntities(participations, 'participation_id')
      Communication.fetchBatchByKey('participation_id', participationIds)

      const participantIds = this.getDistinctIdsFromEntities(participations, 'participant_id')

      const participants = await Participant.fetchBatch(participantIds)

      const postalcodeIds = this.getDistinctIdsFromEntities(participants, 'postalcode_id')

      Postalcode.fetchBatch(postalcodeIds)
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
    this.loading = false
  },
  methods: {
    generateNametagPdf() {
      this.$refs.nametags.generatePdf()
    },
    generateShippingLabelPdf() {
      this.$refs.shippingLabels.generatePdf()
    },
    generateParticipantListPdf() {
      this.$refs.participantList.generatePdf()
    },
    getLivingConfirmedParticipants() {
      return this.confirmedParticipations.filter(participation => {
        if (participation.participant?.deceased == false) {
          return true
        }
      })
    },
    getParticipations(where) {
      const participations = Participation.query()
        .with('communications')
        .with('communications.communicationtype')
        .with('communications.communicationcategory')
        .with('course')
        .with('participant')
        .with('participant.gender')
        .with('participant.postalcode')
        .with('participant.postalcode.municipality')
        .with('participant.postalcode.municipality.county')
        .with('lodgingtype')
        .where('course_id', this.course.course_id)
        .where(where)
        .get()

      participations.sort((a, b) => {
        if (a.participant && a.participant.deceased) {
          return -1
        }
        if (b.participant && b.participant.deceased) {
          return 1
        }
        return 0
      })
      return participations
    },
  },
}
</script>

<style lang="scss" scoped>
dl {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0.5rem;
}

dt:after {
  content: ':';
}

.vue-html2pdf {
  display: none;
}

.participants {
  background: #fff;
  color: #000;
  padding: 5pt;
  display: flex;
  flex-direction: column;
  min-height: 800pt;

  &__list {
    border-spacing: 0;
    font-family: Roboto;
    width: 100%;
    font-size: 10pt;
    box-sizing: border-box;
  }

  &__phone {
    width: 90pt;
  }

  &__missed_hours {
    width: 100pt;
  }

  &__title {
    background: #fff;
    color: #000;
    padding: 10pt;
    font-family: Roboto;
  }

  &__signature {
    &s {
      padding-top: 20pt;
      font-size: 8pt;
      display: flex;
      justify-content: space-around;
      margin-top: auto;
    }

    text-align: center;
    border-top: 1pt solid #000;
    padding: 5pt 40pt;
    font-family: Roboto;
  }

  tr:nth-child(even) {
    background: #eee;
  }

  th {
    text-align: left;
  }

  th,
  td {
    border: none;
    padding: 10pt;
  }
}

.shippingLabels {
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, 105mm);
  grid-template-rows: repeat(8, 37mm);
  align-items: center;
}

.shippingLabel {
  color: #000;
  padding-left: 1em;
  font-family: Roboto;
  font-size: 14pt;

  p {
    margin-bottom: 14pt;
  }
}

.nametags {
  background: #fff;
  padding-left: 40pt;
  padding-top: 6pt;
  display: grid;
  grid-template-columns: repeat(2, 215pt);
  gap: 2pt;
  width: 612pt;
}

.nametag {
  box-sizing: border-box;
  width: 100%;
  height: 117pt;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 30pt;
  font-weight: 500;
  padding-top: 20pt;
  padding-left: 4pt;
  padding-bottom: 4pt;
  background-image: url('../images/nametag.svg');
  background-position: bottom;
  background-size: 100%;
}
</style>
