import axios from 'axios'

import BaseModel from '@/models/BaseModel'
import Communication from '@/models/Communication'
import Course from '@/models/Course'
import Lodgingtype from '@/models/Lodgingtype'
import Participant from '@/models/Participant'
import Trafficsource from '@/models/Trafficsource'

const COURSETYPE_LOCAL = 2
const COURSETYPE_TRAVEL = 4
const LOCAL_PARTICIPATION_URL = 'local_participations'
const TRAVEL_PARTICIPATION_URL = 'travel_participations'

const LOCAL_PARTICIPATION_FIELDS = ['lodgingtype_id', 'lodging_date_from', 'lodging_date_to']
const TRAVEL_PARTICIPATION_FIELDS = [
  'lodgingtype_id',
  'airport_shuttle',
  'airport_lodging',
  'invoicing_info',
  'will_reunite',
]

export default class Participation extends BaseModel {
  static entity = 'participations'
  static primaryKey = 'participation_id'

  static fields() {
    return {
      participation_id: this.number(null),
      participant_id: this.number(null),
      course_id: this.number(null),
      coursetype_id: this.number(null),
      trafficsource_id: this.number(null),
      lodgingtype_id: this.number(null),
      lodging_date_from: this.string().nullable(),
      lodging_date_to: this.string().nullable(),
      interest_date: this.string().nullable(),
      signup_date: this.string().nullable(),
      confirmed_date: this.string().nullable(),
      cancel_date: this.string().nullable(),
      comment: this.string().nullable(),
      hours_missed: this.number().nullable(),
      airport_shuttle: this.string().nullable(),
      airport_lodging: this.string().nullable(),
      will_reunite: this.boolean(),
      invoicing_info: this.string().nullable(),
      participant: this.belongsTo(Participant, 'participant_id'),
      course: this.belongsTo(Course, 'course_id'),
      trafficsource: this.belongsTo(Trafficsource, 'trafficsource_id'),
      lodgingtype: this.belongsTo(Lodgingtype, 'lodgingtype_id'),
      communications: this.hasMany(Communication, 'participation_id', 'participation_id'),
    }
  }

  static async fetchAll(params, config = {}) {
    config.dataTransformer = fetchChildren
    return await super.fetchAll(params, config)
  }

  static async fetchBatchByKey(key, ids, deleteAll = true, config = {}) {
    config.dataTransformer = fetchChildren
    return await super.fetchBatchByKey(key, ids, deleteAll, config)
  }

  static async updateOne(id, data = {}, config = {}) {
    config.dataTransformer = fetchChildren

    const courseTypeId = Participation.find(id).coursetype_id

    if (courseTypeId == COURSETYPE_LOCAL) {
      const childData = {}
      LOCAL_PARTICIPATION_FIELDS.forEach(field => {
        if (field in data) {
          childData[field] = data[field]
          delete data[field]
        }
      })

      if (Object.keys(childData).length > 0) {
        const childResponse = await axios.put(`${LOCAL_PARTICIPATION_URL}/${id}`, childData)
        if (childResponse.data.length) {
          updateChildFieldsInStore(childResponse.data[0])
        }
      }
    } else if (courseTypeId == COURSETYPE_TRAVEL) {
      const childData = {}
      TRAVEL_PARTICIPATION_FIELDS.forEach(field => {
        if (field in data) {
          childData[field] = data[field]
          delete data[field]
        }
      })

      if (Object.keys(childData).length > 0) {
        const childResponse = await axios.put(`${TRAVEL_PARTICIPATION_URL}/${id}`, childData)
        if (childResponse.data.length) {
          updateChildFieldsInStore(childResponse.data[0])
        }
      }
    }

    return await super.updateOne(id, data, config)
  }
}

function fetchChildren(response) {
  const localParticipationIds = []
  const travelParticipationIds = []

  response.data.forEach(participation => {
    if (participation.coursetype_id == COURSETYPE_LOCAL) {
      localParticipationIds.push(participation.participation_id)
    } else if (participation.coursetype_id == COURSETYPE_TRAVEL) {
      travelParticipationIds.push(participation.participation_id)
    }
  })

  if (localParticipationIds.length) {
    axios
      .get(`${LOCAL_PARTICIPATION_URL}?ids=${localParticipationIds.toString()}`)
      .then(response => {
        response.data.forEach(updateChildFieldsInStore)
      })
  }
  if (travelParticipationIds.length) {
    axios
      .get(`${TRAVEL_PARTICIPATION_URL}?ids=${travelParticipationIds.toString()}`)
      .then(response => {
        response.data.forEach(child => {
          updateChildFieldsInStore(child)
        })
      })
  }
  return response.data
}

function updateChildFieldsInStore(child) {
  Participation.update({
    where: child.participation_id,
    data: { ...child },
  })
}
