import { Database } from '@vuex-orm/core'
import Communication from '@/models/Communication'
import Communicationtype from '@/models/Communicationtype'
import Communicationcategory from '@/models/Communicationcategory'
import County from '@/models/County'
import Course from '@/models/Course'
import Coursecategory from '@/models/Coursecategory'
import Courseteacher from '@/models/Courseteacher'
import CourseteacherCoursecategory from '@/models/CourseteacherCoursecategory'
import Coursetype from '@/models/Coursetype'
import Gender from '@/models/Gender'
import Lodgingtype from '@/models/Lodgingtype'
import Municipality from '@/models/Municipality'
import Participant from '@/models/Participant'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'
import Participation from '@/models/Participation'
import Permission from '@/models/Permission'
import Postalcode from '@/models/Postalcode'
import Trafficsource from '@/models/Trafficsource'
import User from '@/models/User'
import UserPermission from '@/models/UserPermission'

const database = new Database()

database.register(Communication, {})
database.register(Communicationcategory, {})
database.register(Communicationtype, {})
database.register(County, {})
database.register(Course, {})
database.register(Coursecategory, {})
database.register(Courseteacher, {})
database.register(CourseteacherCoursecategory, {})
database.register(Coursetype, {})
database.register(Gender, {})
database.register(Lodgingtype, {})
database.register(Municipality, {})
database.register(Participant, {})
database.register(ParticipantCoursetype, {})
database.register(Participation, {})
database.register(Permission, {})
database.register(Postalcode, {})
database.register(Trafficsource, {})
database.register(User, {})
database.register(UserPermission, {})

export default database
