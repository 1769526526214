import BaseModel from '@/models/BaseModel'
import Municipality from '@/models/Municipality'

export default class County extends BaseModel {
  static entity = 'counties'
  static primaryKey = 'county_id'

  static fields() {
    return {
      county_id: this.number(null),
      name: this.string(),
      color: this.string('#2196F3'),
      municipalities: this.hasMany(Municipality, 'county_id'),
    }
  }
}
