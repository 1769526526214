<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row class="mb-16">
      <v-col cols="12" md="6" lg="5">
        <v-card>
          <v-toolbar color="primary" class="white--text">
            <v-toolbar-title
              >{{ participant.last_name }}, {{ participant.first_name }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <ParticipantDialogButton
              type="update"
              color="white"
              @click="showParticipantDialog(participant)"
            />
          </v-toolbar>
          <v-card-text :class="$currentUser.preferences.darkMode ? 'white--text' : ''">
            <dl class="mb-2">
              <dt>Kjønn</dt>
              <dd>{{ participant.gender.name }}</dd>

              <template v-if="participant.birthdate">
                <dt>Fødselsdato</dt>
                <dd>
                  {{ participant.birthdate | date }}
                  <span class="caption text--secondary">
                    ({{ participant.birthdate | age }} år)
                  </span>
                </dd>
              </template>

              <template v-if="participant.phone">
                <dt>Telefon</dt>
                <dd>{{ participant.phone | phone }}</dd>
              </template>

              <template v-if="participant.email">
                <dt>Epost</dt>
                <dd>
                  <a :href="`mailto:${participant.email}`">{{ participant.email }}</a>
                </dd>
              </template>

              <template v-if="participant.address">
                <dt>Adresse</dt>
                <dd>
                  {{ participant.address }}
                  <div v-if="participant.postalcode">
                    {{ participant.postalcode.postalcode }} {{ participant.postalcode.name }}
                    <div
                      v-if="participant.postalcode.municipality"
                      class="font-weight-light text--secondary text-uppercase"
                    >
                      {{ participant.postalcode.municipality.name }}
                      <template v-if="participant.postalcode.municipality.county">
                        • {{ participant.postalcode.municipality.county.name }}
                      </template>
                    </div>
                  </div>
                </dd>
              </template>

              <template v-if="participant.important_info">
                <dt>Viktig info</dt>
                <dd>{{ participant.important_info }}</dd>
              </template>

              <template v-if="participant.disallows_photos">
                <dt>Bildebruk</dt>
                <dd>Reservert <v-icon small color="error">no_photography</v-icon></dd>
              </template>

              <template v-if="participant.disallows_spam">
                <dt>Epostmarkedsføring</dt>
                <dd>Reservert <v-icon small color="error">do_not_disturb_on</v-icon></dd>
              </template>

              <template v-if="participant.deceased">
                <dt>Avdød</dt>
                <dd>Ja</dd>
              </template>

              <template v-if="participant.coursetypes.length">
                <dt>Interessert i</dt>
                <dd>
                  <v-chip
                    v-for="coursetype in participant.coursetypes"
                    :key="coursetype.coursetype_id"
                    :color="coursetype.color"
                    class="mr-1"
                  >
                    {{ coursetype.name }}
                  </v-chip>
                </dd>
              </template>
            </dl>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="participant"
          participation-type="confirmed"
          :participations="confirmedParticipations"
          :loading="loading"
          :participant="participant"
        />
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="participant"
          participation-type="signedUp"
          :participations="signedUpParticipations"
          :loading="loading"
          :participant="participant"
        />
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="participant"
          participation-type="interested"
          :participations="interestedParticipations"
          :loading="loading"
          :participant="participant"
        />
      </v-col>
    </v-row>

    <v-row class="mb-16">
      <v-col cols="12">
        <ParticipationTable
          type="participant"
          participation-type="cancelled"
          :participations="cancelledParticipations"
          :loading="loading"
          :participant="participant"
        />
      </v-col>
    </v-row>

    <EntityDialog
      ref="participantDialog"
      v-bind="participantDialog"
      @entityUpdated="handleParticipantUpdated"
    />
  </v-container>
</template>

<script>
import ParticipantDialogButton from '@/components/buttons/ParticipantDialogButton'
import ParticipationTable from '@/components/datatables/ParticipationTable'
import EntityDialog from '@/components/dialogs/EntityDialog'

import participantDialog from '@/mixins/dialogs/participantDialog.js'
import participations from '@/mixins/participations.js'
import responseHelper from '@/mixins/responseHelper.js'

import County from '@/models/County'
import Course from '@/models/Course'
import Municipality from '@/models/Municipality'
import Participant from '@/models/Participant'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'
import Participation from '@/models/Participation'
import Postalcode from '@/models/Postalcode'

export default {
  components: { EntityDialog, ParticipantDialogButton, ParticipationTable },
  mixins: [participantDialog, participations, responseHelper],
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    participant() {
      return Participant.query()
        .whereId(parseInt(this.$route.params.participant_id))
        .with('gender')
        .with('postalcode')
        .with('postalcode.municipality')
        .with('postalcode.municipality.county')
        .with('coursetypes')
        .first()
    },
  },
  async beforeMount() {
    try {
      const participant = await Participant.fetchOneById(
        parseInt(this.$route.params.participant_id)
      )

      const postalcode = Postalcode.fetchOneById(participant.postalcode_id)
      const municipality = Municipality.fetchOneById(postalcode.municipality_id)
      County.fetchOneById(municipality.county_id)

      ParticipantCoursetype.fetchAll({ search: { participant_id: participant.participant_id } })
      const participations = await Participation.fetchAll({
        search: { participant_id: participant.participant_id },
      })

      const courseIds = this.getDistinctIdsFromEntities(participations, 'course_id')
      Course.fetchBatch(courseIds)
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
    this.loading = false
  },
  methods: {
    getParticipations(where) {
      return Participation.query()
        .with('communications')
        .with('communications.communicationtype')
        .with('communications.communicationcategory')
        .with('course')
        .with('lodgingtype')
        .with('participant')
        .where('participant_id', this.participant.participant_id)
        .where(where)
        .get()
    },
  },
}
</script>

<style lang="scss" scoped>
dl {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 0.5rem;
}

dt {
  white-space: nowrap;

  &:after {
    content: ':';
  }
}
</style>
