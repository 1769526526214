<template>
  <div class="text-center">
    <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
  </div>
</template>

<script>
export default {
  async created() {
    await this.$auth.handleAuthentication()
  },
  methods: {
    async handleLoginEvent(data) {
      await this.$auth.getAccessToken()
      this.$router.push(data.state.target || '/')
      window.location.reload()
    },
  },
}
</script>
