import User from '@/models/User'

export default {
  methods: {
    initPreferences() {
      let thereAreNewPreferences = false

      if (!this.$currentUser.preferences) {
        this.$currentUser.preferences = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.darkMode === undefined) {
        this.$currentUser.preferences.darkMode = true
        thereAreNewPreferences = true
      }
      this.$vuetify.theme.dark = this.$currentUser.preferences.darkMode

      if (this.$currentUser.preferences.views === undefined) {
        this.$currentUser.preferences.views = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.components === undefined) {
        this.$currentUser.preferences.components = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.views.courseteachers === undefined) {
        this.$currentUser.preferences.views.courseteachers = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.views.courseteachers.itemsPerPage === undefined) {
        this.$currentUser.preferences.views.courseteachers.itemsPerPage = 25
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.views.participants === undefined) {
        this.$currentUser.preferences.views.participants = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.views.participants.itemsPerPage === undefined) {
        this.$currentUser.preferences.views.participants.itemsPerPage = 25
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.views.participants.search === undefined) {
        this.$currentUser.preferences.views.participants.search = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.views.participants.search.participant === undefined) {
        this.$currentUser.preferences.views.participants.search.participant = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.components.coursestable === undefined) {
        this.$currentUser.preferences.components.coursestable = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.components.coursestable.itemsPerPage === undefined) {
        this.$currentUser.preferences.components.coursestable.itemsPerPage = 25
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.components.coursestable.search === undefined) {
        this.$currentUser.preferences.components.coursestable.search = {}
        thereAreNewPreferences = true
      }

      if (this.$currentUser.preferences.components.coursestable.search.course === undefined) {
        this.$currentUser.preferences.components.coursestable.search.course = {}
        thereAreNewPreferences = true
      }

      if (
        this.$currentUser.preferences.components.coursestable.search.course.start_date === undefined
      ) {
        this.$currentUser.preferences.components.coursestable.search.course.start_date = {}
        thereAreNewPreferences = true
      }

      if (
        this.$currentUser.preferences.components.coursestable.search.course.signup_deadline ===
        undefined
      ) {
        this.$currentUser.preferences.components.coursestable.search.course.signup_deadline = {}
        thereAreNewPreferences = true
      }

      if (thereAreNewPreferences) {
        this.updatePreferences()
      }
      this.applyPreferences()
    },
    async updatePreferences() {
      await User.updateOne(this.$currentUser.user_id, {
        preferences: this.$currentUser.preferences,
      })
      this.applyPreferences()
    },
    resetPreferences() {
      this.$currentUser.preferences = {}
      this.initPreferences()
    },
    applyPreferences() {
      this.$vuetify.theme.dark = this.$currentUser.preferences.darkMode
    },
  },
}
