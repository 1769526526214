<template>
  <TooltipButton v-bind="participantDialogButton" @click="$emit('click')" />
</template>

<script>
import TooltipButton from '@/components/buttons/TooltipButton'

export default {
  name: 'ParticipantDialogButton',
  components: {
    TooltipButton,
  },
  props: {
    type: {
      type: String,
      default: 'create',
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    participantDialogButton() {
      let icon = 'add'
      let neededPermission = 'PARTICIPANT_CREATE'
      let allowedText = 'Opprett deltaker'
      let disallowedText = 'Du mangler tillatelse til å opprette deltakere'

      if (this.type == 'update') {
        icon = 'edit'
        neededPermission = 'PARTICIPANT_UPDATE'
        allowedText = 'Rediger deltaker'
        disallowedText = 'Du mangler tillatelse til å redigere deltakere'
      }

      return {
        button: {
          icon: icon,
          disabled: !this.$currentUser.hasPermission(neededPermission) || this.loading,
          color: this.color ? this.color : this.$currentUser.preferences.darkMode ? 'white' : '',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission(neededPermission) ? allowedText : disallowedText,
        },
      }
    },
  },
}
</script>
