<template>
  <v-select
    :value="selectedItems"
    :items="items"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :clearable="clearable"
    return-object
    multiple
    :rules="rules"
    :disabled="disabled"
    :filled="filled"
    @input="$emit('input', selectedItems)"
    @change="handleChange"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      },
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data: function() {
    return {
      oldSelectedItems: this.value,
    }
  },
  computed: {
    selectedItems() {
      return this.value
    },
  },
  methods: {
    handleChange(currentItems) {
      const addedItems = []
      for (const currentItem of currentItems) {
        if (!this.oldSelectedItems.includes(currentItem)) {
          addedItems.push(currentItem)
        }
      }

      const removedItems = []
      for (const oldSelectedItem of this.oldSelectedItems) {
        if (!currentItems.includes(oldSelectedItem)) {
          removedItems.push(oldSelectedItem)
        }
      }

      this.oldSelectedItems = currentItems

      currentItems = currentItems.filter(item => {
        return item !== null
      })

      this.$emit('change', { added: addedItems, removed: removedItems, current: currentItems })
    },
  },
}
</script>
