var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"headline"},[_c('span',[_vm._v("NNPS ")]),_c('span',{staticClass:"font-weight-light"},[_vm._v("deltakersystem")])]),_c('v-spacer'),(_vm.isAuthenticated)?_c('v-toolbar-items',[_c('v-btn',{attrs:{"to":{ name: 'home' },"text":"","exact":""}},[_vm._v("Hjem")]),_c('v-btn',{attrs:{"to":{ name: 'courses' },"text":"","exact":""}},[_vm._v("Kurs")]),_c('v-btn',{attrs:{"to":{ name: 'participants' },"text":"","exact":""}},[_vm._v("Deltakere")]),_c('v-btn',{attrs:{"to":{ name: 'courseteachers' },"text":"","exact":""}},[_vm._v("Kurslærere")]),_c('v-menu',{attrs:{"close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Administrasjon")])]}}],null,false,3375735308)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'statistics') {
                _vm.$router.push({ name: 'statistics' })
              }}}},[_c('v-list-item-title',[_vm._v("Kursstatistikk")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'users') {
                _vm.$router.push({ name: 'users' })
              }}}},[_c('v-list-item-title',[_vm._v("Brukere")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'permissions') {
                _vm.$router.push({ name: 'permissions' })
              }}}},[_c('v-list-item-title',[_vm._v("Tillatelser")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'postalcodes') {
                _vm.$router.push({ name: 'postalcodes' })
              }}}},[_c('v-list-item-title',[_vm._v("Postnumre")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'municipalities') {
                _vm.$router.push({ name: 'municipalities' })
              }}}},[_c('v-list-item-title',[_vm._v("Kommuner")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'counties') {
                _vm.$router.push({ name: 'counties' })
              }}}},[_c('v-list-item-title',[_vm._v("Fylker")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'coursetypes') {
                _vm.$router.push({ name: 'coursetypes' })
              }}}},[_c('v-list-item-title',[_vm._v("Kurstyper")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'coursecategories') {
                _vm.$router.push({ name: 'coursecategories' })
              }}}},[_c('v-list-item-title',[_vm._v("Kurskategorier")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'trafficsources') {
                _vm.$router.push({ name: 'trafficsources' })
              }}}},[_c('v-list-item-title',[_vm._v("Trafikkilder")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'communicationtypes') {
                _vm.$router.push({ name: 'communicationtypes' })
              }}}},[_c('v-list-item-title',[_vm._v("Kommunikasjonstyper")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'communicationcategories') {
                _vm.$router.push({ name: 'communicationcategories' })
              }}}},[_c('v-list-item-title',[_vm._v("Kommunikasjonskategorier")])],1),_c('v-list-item',{on:{"click":function($event){if (_vm.$route.name !== 'lodgingtypes') {
                _vm.$router.push({ name: 'lodgingtypes' })
              }}}},[_c('v-list-item-title',[_vm._v("Innlosjeringstyper")])],1)],1)],1)],1):_vm._e(),_c('v-spacer'),(_vm.isAuthenticated)?_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'profile' }}},on),[_c('v-icon',[_vm._v("person")])],1)]}}],null,false,1913025346)},[_c('span',[_vm._v("Din profil")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("exit_to_app")])],1)]}}],null,false,3501620511)},[_c('span',[_vm._v("Logg ut")])])],1):_vm._e(),(!_vm.isAuthenticated)?_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.login}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logg inn")]),_c('v-icon',[_vm._v("exit_to_app")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }