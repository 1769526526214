<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card :loading="loading">
          <v-toolbar color="success">
            <v-toolbar-title>Din profil</v-toolbar-title>
          </v-toolbar>
          <v-row class="pa-5">
            <v-col cols="12" md="8" lg="6">
              <h2>Brukerinformasjon</h2>
              <v-list>
                <v-list-item v-show="!editing.name" @click="startEditing('name')">
                  <v-list-item-icon>
                    <v-icon>person</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $currentUser.first_name }} {{ $currentUser.last_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-show="editing.name">
                  <v-list-item-content>
                    <v-container class="py-0">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedUser.first_name"
                            label="Fornavn"
                            required
                            class="py-0 my-0"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="editedUser.last_name"
                            label="Etternavn"
                            required
                            class="py-0 my-0"
                          />
                        </v-col>
                        <v-col>
                          <v-btn icon color="success" @click="saveEdited('name')"
                            ><v-icon>check</v-icon></v-btn
                          >
                          <v-btn icon color="error" @click="cancelEditing('name')">
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item disabled>
                  <v-list-item-icon>
                    <v-icon>email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $currentUser.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-show="!editing.phone" @click="startEditing('phone')">
                  <v-list-item-icon>
                    <v-icon>smartphone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $currentUser.phone | phone }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-show="editing.phone">
                  <v-list-item-content>
                    <v-container class="py-0">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="editedUser.phone"
                            label="Telefon"
                            required
                            class="mr-1 py-0 my-0"
                        /></v-col>
                        <v-col>
                          <v-btn icon color="success" @click="saveEdited('phone')"
                            ><v-icon>check</v-icon></v-btn
                          >
                          <v-btn icon color="error" @click="cancelEditing('phone')">
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <h2>Innstillinger</h2>
              <v-switch
                v-if="$currentUser"
                v-model="$currentUser.preferences.darkMode"
                label="Dark mode"
                @change="updatePreferences"
              />
              <v-btn v-if="$currentUser" color="primary" @click="resetPreferences()">
                Tilbakestill alle innstillinger
              </v-btn>
            </v-col>
          </v-row>
          <div class="px-4 pb-1">
            <ErrorAlert v-bind="errorAlert" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import errorAlert from '@/mixins/errorAlert'
import preferences from '@/mixins/preferences'
import ErrorAlert from '@/components/ErrorAlert'
import User from '@/models/User'

export default {
  components: {
    ErrorAlert,
  },
  mixins: [errorAlert, preferences],
  data: () => {
    return {
      loading: false,
      editing: {
        name: false,
        phone: false,
      },
      editedUser: {
        first_name: '',
        last_name: '',
        phone: '',
      },
    }
  },
  //TODO fetch user by email on create, to ensure we have the latest user info
  methods: {
    startEditing(field) {
      this.editedUser.first_name = this.$currentUser.first_name
      this.editedUser.last_name = this.$currentUser.last_name
      this.editedUser.phone = this.$currentUser.phone
      this.editing[field] = true
    },

    async saveEdited(field) {
      let data = {}

      if (field == 'name') {
        data.first_name = this.editedUser.first_name
        data.last_name = this.editedUser.last_name
      } else {
        data[field] = this.editedUser[field]
      }

      await this.updateUserDetails(data)

      this.editing[field] = false
    },

    cancelEditing(field) {
      this.editing[field] = false
    },

    async updateUserDetails(data) {
      this.loading = true
      this.clearErrorAlert()

      try {
        await User.updateOne(this.$currentUser.user_id, data)
      } catch (e) {
        this.handleError(e.message)
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>
ul {
  padding-left: 0;
}

li {
  list-style: none;
}
</style>
