var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$currentUser)?_c('v-container',{staticClass:"py-16 px-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"success"}},[_c('v-toolbar-title',[_vm._v("Kurslærere")]),_c('v-spacer'),_c('CourseteacherDialogButton',{attrs:{"type":"create","color":"white"},on:{"click":function($event){return _vm.showCourseteacherDialog()}}})],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.courseteachers)?_c('v-data-table',_vm._b({attrs:{"items":_vm.courseteachers,"item-key":"courseteacher_id","loading":_vm.loading,"disable-pagination":_vm.loading,"disable-sort":_vm.loading,"loading-text":"Oppdaterer"},on:{"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"nowrap",staticStyle:{"text-decoration":"none"},attrs:{"to":{
                  path: ("/courseteachers/" + (item.courseteacher_id)),
                }}},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"item.phone",fn:function(ref){
                var item = ref.item;
return [_c('a',{staticClass:"nowrap",attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(_vm._f("phone")(item.phone)))])]}},{key:"item.email",fn:function(ref){
                var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.contract",fn:function(ref){
                var item = ref.item;
return [(item.contract)?[_vm._v("Ja")]:_vm._e()]}},{key:"item.coursecategories",fn:function(ref){
                var item = ref.item;
return _vm._l((item.coursecategories),function(coursecategory){return _c('span',{key:coursecategory.coursecategory_id,staticClass:"coursecategory"},[_vm._v(" "+_vm._s(coursecategory.name)+" ")])})}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('CourseteacherDialogButton',{attrs:{"type":"update","color":"white"},on:{"click":function($event){return _vm.showCourseteacherDialog(item)}}}),_c('TooltipButton',_vm._b({on:{"click":function($event){return _vm.getDeleteConfirmation(
                    item,
                    ("Bekreft sletting av kurslærer " + (item.full_name)),
                    ("Vil du virkelig slette kurslæreren " + (item.full_name) + " for alltid?")
                  )}}},'TooltipButton',_vm.deleteCourseteacherButton,false))]}}],null,false,3763521632)},'v-data-table',_vm.courseteachersTable,false,true)):_vm._e()],1)],1)],1),_c('EntityDialog',_vm._b({ref:"courseteacherDialog",on:{"entityCreated":_vm.handleCourseteacherCreated,"entityUpdated":_vm.handleCourseteacherUpdated}},'EntityDialog',_vm.courseteacherDialog,false)),_c('DeleteConfirmationDialog',_vm._b({ref:"deleteConfirmationDialog",on:{"cancel":_vm.closeAndResetDeleteConfirmationDialog,"success":function($event){return _vm.closeAndResetDeleteConfirmationDialog(
          ("Du slettet kurslæreren " + (_vm.deleteConfirmationDialog.entityToDelete.full_name))
        )}}},'DeleteConfirmationDialog',_vm.deleteConfirmationDialog,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }