<template>
  <BaseDialog
    v-if="entity"
    ref="baseDialog"
    :show="show"
    :entity="entity"
    :create-title="createTitle"
    :edit-title="editTitle"
    :max-width="maxWidth"
    :loading="loading"
    @closeAndReset="closeDialog"
  >
    <template slot="form">
      <v-form ref="form" autocomplete="off">
        <span v-for="field in fields" :key="field.name">
          <v-text-field
            v-if="field.type == 'text'"
            v-model="entity[field.name]"
            :type="field.textInputType"
            :label="field.label"
            :clearable="field.clearable"
            :hint="field.hint"
            :persistent-hint="field.persistentHint"
            :rules="field.rules"
            :disabled="loading"
            :messages="field.messages"
            :min="field.min"
            :max="field.max"
            @change="
              field.changeHandler
                ? field.changeHandler(field.name, ...arguments)
                : handleField(field.name, ...arguments)
            "
          />
          <LookupAutocomplete
            v-if="field.type == 'lookupAutocomplete'"
            v-model="entity[field.name]"
            :label="field.label"
            :rules="field.rules"
            :loading="loading"
            :disabled="loading || field.disabled"
            :item="field.item"
            :item-value="field.itemValue"
            :item-text="field.itemText"
            :filters="field.filters"
            :model="field.model"
            @input="
              field.inputHandler
                ? field.inputHandler(field.name, ...arguments)
                : handleField(field.name, ...arguments)
            "
          />
          <ItemTextfield
            v-if="field.type == 'itemText'"
            v-model="entity[field.name]"
            :label="field.label"
            :rules="field.rules"
            :disabled="loading"
            :item="entity[field.item]"
            :item-value="field.itemValue"
            :item-text="field.itemText"
            :no-data-text="field.noDataText"
            :suffix-text="field.suffixText"
            :min-length="field.minLength"
            :max-length="field.maxLength"
            :model="field.model"
            @input="handleField(field.name, ...arguments)"
          />
          <ColorSelect
            v-if="field.type == 'color'"
            v-model="entity[field.name]"
            :color="entity[field.name]"
            :label="field.label"
            :rules="field.rules"
            :disabled="loading"
            @input="handleField(field.name, ...arguments)"
          />
          <MultiSelect
            v-if="field.type == 'multiselect'"
            v-model="entity[field.name]"
            :label="field.label"
            :items="field.items"
            :item-text="field.itemText"
            :item-value="field.itemValue"
            :no-data-text="field.noDataText"
            :rules="field.rules"
            :clearable="field.clearable"
            :multiple="field.multiple"
            :disabled="field.disabled || loading"
            @change="
              field.changeHandler
                ? field.changeHandler(field.name, ...arguments)
                : handleField(field.name, ...arguments)
            "
          />
          <v-select
            v-if="field.type == 'select'"
            v-model="entity[field.name]"
            :label="field.label"
            :items="field.items"
            :item-text="field.itemText"
            :item-value="field.itemValue"
            :no-data-text="field.noDataText"
            :rules="field.rules"
            :clearable="field.clearable"
            :multiple="field.multiple"
            :disabled="field.disabled || loading"
            @change="handleField(field.name, ...arguments)"
          />
          <v-radio-group
            v-if="field.type == 'radio'"
            v-model="entity[field.name]"
            :label="field.label"
            :rules="field.rules"
            :disabled="loading"
            @change="handleField(field.name, ...arguments)"
          >
            <v-radio
              v-for="radio in field.items"
              :key="radio[field.key]"
              :label="radio.name"
              :value="radio[field.value]"
            />
          </v-radio-group>
          <v-textarea
            v-if="field.type == 'textarea'"
            v-model="entity[field.name]"
            :label="field.label"
            :hint="field.hint"
            :persistent-hint="field.persistentHint"
            :rows="field.rows"
            :auto-grow="field.autoGrow"
            :rules="field.rules"
            :disabled="loading"
            :messages="field.messages"
            @change="handleField(field.name, ...arguments)"
          />
          <v-switch
            v-if="field.type == 'switch'"
            v-model="entity[field.name]"
            :label="field.label"
            @change="handleField(field.name, ...arguments)"
          />
        </span>
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence'
import BaseDialog from '@/components/dialogs/BaseDialog'
import ColorSelect from '@/components/controls/ColorSelect'
import MultiSelect from '@/components/controls/MultiSelect'
import ItemTextfield from '@/components/controls/ItemTextfield'
import LookupAutocomplete from '@/components/controls/LookupAutocomplete'

export default {
  name: 'EntityDialog',
  components: {
    BaseDialog,
    ColorSelect,
    ItemTextfield,
    LookupAutocomplete,
    MultiSelect,
  },
  mixins: [entityPersistence],
  props: {
    createTitle: {
      type: String,
      default: 'Opprett',
    },
    editTitle: {
      type: String,
      default: 'Rediger',
    },
    fields: {
      type: Array,
      default: () => {
        return []
      },
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
  },
  data: () => {
    return {
      show: false,
      loading: false,
      entity: null,
    }
  },
  methods: {
    async handleField(fieldName, value) {
      if (!this.show) return
      if (!this.$refs.form.validate()) return

      if (value === undefined || value === '') {
        value = null
      }

      this.$refs.baseDialog.clearErrorAlert()

      const data = {}
      data[fieldName] = value

      if (this.entity.$id) {
        await this.createOrUpdateEntity(this.entity.constructor, data, this.entity.$id)
      } else {
        await this.createEntity()
      }
    },

    async createEntity() {
      this.$refs.baseDialog.clearErrorAlert()
      await this.createOrUpdateEntity(this.entity.constructor, this.entity)
    },

    handleError(message) {
      this.$refs.baseDialog.showErrorAlert(message)
      this.loading = false
    },

    showDialog(model, instance = {}) {
      if (typeof instance != model.constructor) {
        instance = new model(instance)
      }

      this.entity = instance
      this.show = true
    },

    closeDialog() {
      this.$refs.baseDialog.clearErrorAlert()
      this.entity = null
      this.show = false
    },
  },
}
</script>
