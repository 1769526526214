import BaseModel from '@/models/BaseModel'

export default class Trafficsource extends BaseModel {
  static entity = 'trafficsources'
  static primaryKey = 'trafficsource_id'

  static fields() {
    return {
      trafficsource_id: this.number(null),
      name: this.string(),
    }
  }
}
