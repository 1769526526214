<template>
  <div>
    <v-dialog v-if="show" value="show" persistent max-width="600px">
      <v-card class="text-center pb-2">
        <v-card-title class="headline d-block text-center white--text warning">
          {{ title }}
        </v-card-title>
        <v-progress-linear v-if="loading" indeterminate />
        <v-card-text class="py-8">
          {{ text }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="mr-2" color="primary" :disabled="loading" @click="$emit('accepted')"
            >Bekreft</v-btn
          >
          <v-btn class="ml-2" outlined :disabled="loading" @click="$emit('declined')">Avslå</v-btn>
        </v-card-actions>
        <ErrorAlert class="mx-4" v-bind="errorAlert" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorAlert from '@/mixins/errorAlert'
import ErrorAlert from '@/components/ErrorAlert'
export default {
  name: 'ConfirmationDialog',
  components: {
    ErrorAlert,
  },
  mixins: [errorAlert],
  props: {
    show: Boolean,
    loading: Boolean,
    title: {
      type: String,
      default: 'Bekreft',
    },
    text: {
      type: String,
      default: 'Ønsker du virkelig dette?',
    },
  },
}
</script>
