import Communication from '@/models/Communication'
import Communicationtype from '@/models/Communicationtype'
import Communicationcategory from '@/models/Communicationcategory'

export default {
  data: () => {
    return {
      selectedCommunication: null,
    }
  },
  computed: {
    communicationDialog() {
      return {
        createTitle: 'Opprett ny kommunikasjon',
        editTitle: 'Rediger kommunikasjon',
        maxWidth: 640,
        fields: [
          {
            name: 'communicationtype_id',
            type: 'select',
            label: 'Type',
            items: Communicationtype.query().get(),
            itemText: 'name',
            itemValue: 'communicationtype_id',
            rules: [this.$validationRules.required],
          },
          {
            name: 'communicationcategory_id',
            type: 'select',
            label: 'Kategori',
            items: Communicationcategory.query().get(),
            itemText: 'name',
            itemValue: 'communicationcategory_id',
            rules: [this.$validationRules.required],
          },
          {
            name: 'date',
            type: 'text',
            textInputType: 'date',
            label: 'Dato',
            rules: [this.$validationRules.required],
          },
          {
            name: 'comment',
            type: 'textarea',
            label: 'Kommentar',
            rows: 1,
            autoGrow: true,
          },
        ],
      }
    },
  },
  methods: {
    showCommunicationDialog(communication) {
      this.selectedCommunication = communication
      this.$refs.communicationDialog.showDialog(Communication, communication)
    },
    async handleCommunicationCreated(communication) {
      this.$refs.communicationDialog.entity = communication
      this.selectedCommunication = communication

      this.$successToast(`Kommunikasjonen er opprettet`)
    },
    handleCommunicationUpdated(communication) {
      this.$refs.communicationDialog.entity = Communication.query()
        .whereId(communication.communication_id)
        .first()

      this.$successToast(`Endringen av kommunikasjonen er lagret`)
    },
  },
}
