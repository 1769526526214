import BaseModel from '@/models/BaseModel'
import Communicationtype from '@/models/Communicationtype'
import Communicationcategory from '@/models/Communicationcategory'

export default class Communication extends BaseModel {
  static entity = 'communications'
  static primaryKey = 'communication_id'

  static fields() {
    return {
      communication_id: this.number(null),
      participation_id: this.number(null),
      communicationcategory_id: this.number(null),
      communicationtype_id: this.number(null),
      date: this.string(),
      comment: this.string().nullable(),
      communicationtype: this.belongsTo(Communicationtype, 'communicationtype_id'),
      communicationcategory: this.belongsTo(Communicationcategory, 'communicationcategory_id'),
    }
  }
}
