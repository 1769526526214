import Course from '@/models/Course'
import Coursecategory from '@/models/Coursecategory'
import Courseteacher from '@/models/Courseteacher'
import Coursetype from '@/models/Coursetype'

export default {
  data: () => {
    return {
      selectedCourse: null,
    }
  },
  computed: {
    courseDialog() {
      const courseDialog = {
        createTitle: 'Opprett nytt kurs',
        editTitle: 'Rediger kurs',
        maxWidth: 640,
      }

      courseDialog.fields = [...this.getCommonCourseFields(), ...this.getCoursetypeSpecificFields()]

      return courseDialog
    },
    courseteachers() {
      if (!this.selectedCourse) return []

      return Courseteacher.query()
        .whereHas('coursecategories', query => {
          query.where('coursecategory_id', this.selectedCourse.coursecategory_id)
        })
        .get()
    },
  },
  methods: {
    showCourseDialog(course) {
      this.selectedCourse = course
      this.$refs.courseDialog.showDialog(Course, course)
    },
    handleCourseCreated(course) {
      this.$refs.courseDialog.entity = course
      this.selectedCourse = course
      this.$successToast(`Du opprettet kurset ${course.title}`)
    },
    handleCourseUpdated(course) {
      this.$refs.courseDialog.entity = Course.query()
        .whereId(course.course_id)
        .withAll()
        .first()

      this.$successToast(`Endringen av kurset ${course.title} er lagret`)
    },
    getCommonCourseFields() {
      return [
        {
          name: 'title',
          type: 'text',
          label: 'Tittel',
          rules: [this.$validationRules.required],
        },
        {
          name: 'coursetype_id',
          type: 'select',
          label: 'Type',
          items: Coursetype.query().get(),
          itemText: 'name',
          itemValue: 'coursetype_id',
          disabled: this.selectedCourse ? true : false, //once a coursetype is saved, it cannot be changed, to avoid having to delete the sub-course and create a new one.
          rules: [this.$validationRules.required],
        },
        {
          name: 'coursecategory_id',
          type: 'select',
          label: 'Kategori',
          items: Coursecategory.query().get(),
          itemText: 'name',
          itemValue: 'coursecategory_id',
          rules: [this.$validationRules.required],
        },
        {
          name: 'courseteacher_id',
          type: 'select',
          label: 'Kurslærer',
          items: this.courseteachers,
          itemText: 'full_name',
          itemValue: 'courseteacher_id',
          clearable: true,
          disabled: this.selectedCourse && this.selectedCourse.coursecategory_id ? undefined : true,
        },
        {
          name: 'subject_code',
          type: 'text',
          label: 'Emnekode',
          clearable: true,
        },
        {
          name: 'start_date',
          type: 'text',
          textInputType: 'date',
          label: 'Startdato',
          clearable: true,
        },
        {
          name: 'end_date',
          type: 'text',
          textInputType: 'date',
          label: 'Sluttdato',
          clearable: true,
        },
        {
          name: 'price',
          type: 'text',
          textInputType: 'number',
          label: 'Pris',
          clearable: true,
        },
        {
          name: 'hours_total',
          type: 'text',
          textInputType: 'number',
          label: 'Antall timer',
          clearable: true,
          min: 0,
          rules: [this.$validationRules.positive],
        },
        {
          name: 'signup_deadline',
          type: 'text',
          textInputType: 'date',
          label: 'Påmeldingsfrist',
          clearable: true,
        },
        {
          name: 'min_participants',
          type: 'text',
          textInputType: 'number',
          label: 'Minimum deltakere',
          clearable: true,
        },
        {
          name: 'max_participants',
          type: 'text',
          textInputType: 'number',
          label: 'Maximum deltakere',
          clearable: true,
        },
        {
          name: 'description_link',
          type: 'text',
          textInputType: 'url',
          label: 'URL til kursbeskrivelse',
          clearable: true,
          rules: [this.$validationRules.url],
        },
        {
          name: 'comment',
          type: 'textarea',
          label: 'Kommentar',
          rows: 1,
          autoGrow: true,
        },
        {
          name: 'inactive',
          type: 'switch',
          label: 'Deaktivert',
        },
      ]
    },
    getCoursetypeSpecificFields() {
      if (!this.selectedCourse) return []

      if (this.selectedCourse.coursetype_id == Coursetype.TRAVEL_COURSE) {
        return [
          {
            name: 'reunion_date',
            type: 'text',
            textInputType: 'date',
            label: 'Dato for ettersamling',
            clearable: true,
          },
        ]
      }

      if (this.selectedCourse.coursetype_id == Coursetype.EXTERNAL_COURSE) {
        return [
          {
            name: 'location',
            type: 'text',
            label: 'Kurssted',
            clearable: true,
          },
        ]
      }

      return []
    },
  },
}
