import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import { setupStore } from './store'
import AuthPlugin from './plugins/auth'
import currentUser from '@/mixins/currentUser'
import validationRules from '@/mixins/validationRules'
import toast from '@/mixins/toast'
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)

Vue.use(AuthPlugin)

Vue.prototype.$moment = moment
moment.locale('nb')

Vue.mixin(currentUser)
Vue.mixin(validationRules)
Vue.mixin(toast)

Vue.filter('moment', (val, formatOut, formatIn = '') => {
  if (val) {
    return moment(val, formatIn).format(formatOut)
  }
})
Vue.filter('date', val => {
  if (val) {
    return moment(val).format('DD.MM.YYYY')
  }
})
Vue.filter('dayAndMonth', val => {
  if (val) {
    return moment(val).format('DD.MM')
  }
})
Vue.filter('age', val => {
  if (val) {
    return moment().diff(val, 'years')
  }
})
Vue.filter('currency', val => {
  if (val) {
    return Number(val)
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
})
Vue.filter('bankaccount', val => {
  if (!val || typeof val != 'string' || val.length != 11) return val

  return val.replace(/^(.{4})(.{2})(.*)$/, '$1 $2 $3')
})

Vue.filter('phone', val => {
  if (val && !isNaN(val)) {
    return val
      .toString()
      .replace(/(.{2})/g, '$1 ')
      .trim()
  }
  return val
})
Vue.filter('truncateString', (originalString, numLetters, ellipsis = false) => {
  if (!originalString || !numLetters) {
    return
  }

  const truncatedString = originalString.substr(0, numLetters)

  if (ellipsis && truncatedString.length < originalString.length) {
    return `${truncatedString}...`
  }

  return truncatedString
})
Vue.filter('capitalizeFirstLetter', string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
})
Vue.config.productionTip = false

setupStore()
  .then(store => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
  .catch(e => {
    console.log('Error setting up store: ', e)
  })
