<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row>
      <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Kommunikasjonkategorier</v-toolbar-title>
            <v-spacer />
            <TooltipButton
              v-bind="createCommunicationcategoryButton"
              @click="$refs.entityDialog.showDialog(model)"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <v-list>
            <v-list-item
              v-for="communicationcategory in communicationcategories"
              :key="communicationcategory.communicationcategory_id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ communicationcategory.name }}
                </v-list-item-title>
              </v-list-item-content>

              <TooltipButton
                v-bind="updateCommunicationcategoryButton"
                @click="$refs.entityDialog.showDialog(model, communicationcategory)"
              />

              <TooltipButton
                v-bind="deleteCommunicationcategoryButton"
                @click="
                  getDeleteConfirmation(
                    communicationcategory,
                    `Bekreft sletting av kommunikasjonkategori ${communicationcategory.name}`,
                    `Vil du virkelig slette kommunikasjonkategorien ${communicationcategory.name} for alltid?`
                  )
                "
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="entityDialog"
      v-bind="entityDialog"
      @entityCreated="handleEntityCreated"
      @entityUpdated="handleEntityUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet kommunikasjonkategorien ${deleteConfirmationDialog.entityToDelete.name}`
        )
      "
    />
  </v-container>
</template>

<script>
import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'
import TooltipButton from '@/components/buttons/TooltipButton'
import Communicationcategory from '@/models/Communicationcategory'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog],
  data() {
    return {
      loading: false,
      model: Communicationcategory,
    }
  },
  computed: {
    entityDialog() {
      return {
        createTitle: 'Opprett ny kommunikasjonkategori',
        editTitle: 'Rediger kommunikasjonkategori',
        maxWidth: 500,
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Navn',
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
    createCommunicationcategoryButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            !this.$currentUser.hasPermission('COMMUNICATIONCATEGORY_CREATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COMMUNICATIONCATEGORY_CREATE')
            ? 'Opprett kommunikasjonkategori'
            : 'Du mangler tillatelse til å opprette kommunikasjonkategorier',
        },
      }
    },
    updateCommunicationcategoryButton() {
      return {
        button: {
          icon: 'edit',
          disabled:
            !this.$currentUser.hasPermission('COMMUNICATIONCATEGORY_UPDATE') || this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COMMUNICATIONCATEGORY_UPDATE')
            ? 'Rediger kommunikasjonkategori'
            : 'Du mangler tillatelse til å redigere kommunikasjonkategorier',
        },
      }
    },
    deleteCommunicationcategoryButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled:
            !this.$currentUser.hasPermission('COMMUNICATIONCATEGORY_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COMMUNICATIONCATEGORY_DELETE')
            ? 'Slett kommunikasjonkategori'
            : 'Du mangler tillatelse til å slette kommunikasjonkategorier',
        },
      }
    },
    communicationcategories() {
      return Communicationcategory.query()
        .orderBy('name')
        .get()
    },
  },
  methods: {
    handleEntityCreated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Du opprettet kommunikasjonkategorien ${instance.name}`)
    },
    handleEntityUpdated(instance) {
      this.$refs.entityDialog.entity = instance
      this.$successToast(`Endringen av kommunikasjonkategorien ${instance.name} er lagret`)
    },
  },
}
</script>
