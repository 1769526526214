<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Kurslærere</v-toolbar-title>
            <v-spacer />
            <CourseteacherDialogButton
              type="create"
              color="white"
              @click="showCourseteacherDialog()"
            />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />
          <!--
          //TODO: filters for courseteachers
          <v-row align="center" class="mt-2 mx-2">
            <v-col cols="4">
              <v-text-field
                v-model="search.courseteacher.full_name"
                filled
                label="Navn"
                clearable
                prepend-icon="filter_alt"
                :disabled="loading"
                @change="updateSearch"
              />
            </v-col>
            <v-col cols="3">
              <v-radio-group
                v-model="search.courseteacher.gender_id"
                label="Kjønn"
                :disabled="loading"
                @change="updateSearch"
              >
                <v-radio
                  v-for="gender in [{ name: 'Alle' }, ...genders]"
                  :key="gender.gender_id"
                  :label="gender.name"
                  :value="gender.gender_id"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="search.courseteacher.deceased"
                label="Avdød"
                :disabled="loading"
                @change="updateSearch"
              />
            </v-col>
          </v-row>
-->
          <v-data-table
            v-if="courseteachers"
            :items="courseteachers"
            item-key="courseteacher_id"
            :loading="loading"
            :disable-pagination="loading"
            :disable-sort="loading"
            v-bind.sync="courseteachersTable"
            loading-text="Oppdaterer"
            @update:items-per-page="updateItemsPerPage"
          >
            <template v-slot:item.last_name="{ item }">
              <router-link
                :to="{
                  path: `/courseteachers/${item.courseteacher_id}`,
                }"
                style="text-decoration: none;"
                class="nowrap"
              >
                {{ item.full_name }}
              </router-link>
            </template>

            <template v-slot:item.phone="{ item }">
              <a :href="`tel:${item.phone}`" class="nowrap">{{ item.phone | phone }}</a>
            </template>

            <template v-slot:item.email="{ item }">
              <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>

            <template v-slot:item.contract="{ item }">
              <template v-if="item.contract">Ja</template>
            </template>

            <template v-slot:item.coursecategories="{ item }">
              <span
                v-for="coursecategory in item.coursecategories"
                :key="coursecategory.coursecategory_id"
                class="coursecategory"
              >
                {{ coursecategory.name }}
              </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <CourseteacherDialogButton
                type="update"
                color="white"
                @click="showCourseteacherDialog(item)"
              />
              <TooltipButton
                v-bind="deleteCourseteacherButton"
                @click="
                  getDeleteConfirmation(
                    item,
                    `Bekreft sletting av kurslærer ${item.full_name}`,
                    `Vil du virkelig slette kurslæreren ${item.full_name} for alltid?`
                  )
                "
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="courseteacherDialog"
      v-bind="courseteacherDialog"
      @entityCreated="handleCourseteacherCreated"
      @entityUpdated="handleCourseteacherUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet kurslæreren ${deleteConfirmationDialog.entityToDelete.full_name}`
        )
      "
    />
  </v-container>
</template>

<script>
import CourseteacherDialogButton from '@/components/buttons/CourseteacherDialogButton'
import TooltipButton from '@/components/buttons/TooltipButton'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'

import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import courseteacherDialog from '@/mixins/dialogs/courseteacherDialog.js'
import preferences from '@/mixins/preferences.js'
import responseHelper from '@/mixins/responseHelper.js'

import Courseteacher from '@/models/Courseteacher'

export default {
  components: {
    EntityDialog,
    DeleteConfirmationDialog,
    CourseteacherDialogButton,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog, courseteacherDialog, preferences, responseHelper],
  data() {
    return {
      loading: false,
      model: Courseteacher,
    }
  },
  computed: {
    search() {
      return this.$currentUser.preferences.views.courseteachers.search
    },
    courseteachersTable() {
      return {
        headers: [
          { text: 'Navn', value: 'last_name' },
          { text: 'Telefon', value: 'phone', sortable: false },
          { text: 'Epost', value: 'email', sortable: false },
          { text: 'Adresse', value: 'address', sortable: false },
          { text: 'Har arbeidsavtale', value: 'contract', sortable: false },
          { text: 'Fagområder', value: 'coursecategories', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [1, 10, 25, 50, 100, 200],
        },
        options: {
          itemsPerPage: this.$currentUser.preferences.views.courseteachers.itemsPerPage,
          page: 1,
          sortBy: ['last_name'],
          sortDesc: [false],
        },
      }
    },
    deleteCourseteacherButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('COURSETEACHER_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('COURSETEACHER_DELETE')
            ? 'Slett kurslærer'
            : 'Du mangler tillatelse til å slette kurslærere',
        },
      }
    },
    courseteachers() {
      let query = Courseteacher.query().with('coursecategories')

      this.courseteachersTable.options.sortBy.forEach((sortField, i) => {
        query = query.orderBy(
          sortField,
          this.courseteachersTable.options.sortDesc[i] ? 'desc' : 'asc'
        )
      })

      return query.get()
    },
  },
  methods: {
    updateItemsPerPage() {
      if (
        this.$currentUser.preferences.views.courseteachers.itemsPerPage !=
        this.courseteachersTable.options.itemsPerPage
      ) {
        this.$currentUser.preferences.views.courseteachers.itemsPerPage = this.courseteachersTable.options.itemsPerPage
        this.updatePreferences()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.coursecategory:not(:last-of-type):after {
  content: ', ';
}
</style>
