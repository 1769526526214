<template>
  <v-autocomplete
    v-model="selectedItem"
    chips
    deletable-chips
    :items="items"
    :loading="searching"
    :search-input.sync="search"
    color="white"
    hide-no-data
    hide-selected
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled || searching || loading"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    clearable
    return-object
    @change="handleItemSelected"
  />
</template>

<script>
import debounce from 'debounce'
import responseHelper from '@/mixins/responseHelper'

export default {
  name: 'LookupAutocomplete',
  components: {},
  mixins: [responseHelper],
  props: {
    label: {
      type: String,
      default: 'Søk',
    },
    placeholder: {
      type: String,
      default: 'Skriv for å søke',
    },
    item: {
      type: Object,
      default: null,
    },
    itemText: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
    filters: {
      type: Array,
      default: () => {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Function,
      default: null,
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      searching: false,
      search: null,
      items: this.item ? [this.item] : [],
      currentItem: this.item ? this.item : null,
    }
  },
  computed: {
    selectedItem: {
      get() {
        if (!this.currentItem) return null

        return this.currentItem[this.itemValue]
      },
      async set(item) {
        this.currentItem = item
        this.$emit('input', item ? item[this.itemValue] : null)
      },
    },
  },
  watch: {
    search: {
      handler: debounce(async function(value) {
        if (this.searching) return
        if (!value) return
        if (value.length < 3) return
        if (this.selectedItem && value == this.selectedItem[this.itemText]) return

        this.searching = true

        const search = {}
        search[this.itemText] = value

        await this.model.fetchAll({ search: search })

        this.items = this.model
          .query()
          .where(item => {
            return item[this.itemText].toLowerCase().includes(value.toLowerCase())
          })
          .withAll()
          .get()

        this.filters.forEach(filter => {
          this.items = this.items.filter(item => item[filter.key] == filter.value)
        })

        if (!this.items.length) {
          this.selectedItem = null
        }

        this.searching = false
      }, 500),
    },
  },
  beforeMount() {
    if (this.value) {
      const item = this.model.find(this.value)
      this.items.push(item)
    }
  },
  methods: {
    handleItemSelected(selectedItem) {
      if (!selectedItem) {
        this.selectedItem = null
        this.items = []
      }

      this.$emit('change', this.selectedItem)
    },
  },
}
</script>
