export default {
  methods: {
    $successToast(text) {
      if (!text) {
        return
      }

      this.$emit('toast', {
        text: text,
        props: { color: 'success', timeout: '4000', bottom: true },
      })
    },
  },
}
