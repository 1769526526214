<template>
  <v-container v-if="$currentUser" class="py-16 px-8">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="success" class="white--text">
            <v-toolbar-title>Deltakere</v-toolbar-title>
            <v-spacer />
            <TooltipButton v-bind="sendEmailsButton" @click="sendEmails" />
            <ExportContactinfoButton
              v-if="participants.length"
              :custom-handler="true"
              @click="exportContactinfo"
            />
            <ParticipantDialogButton type="create" color="white" @click="showParticipantDialog()" />
          </v-toolbar>
          <v-progress-linear v-if="loading" indeterminate />

          <v-row class="mt-2 mx-2">
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                v-model="search.participant.last_name"
                filled
                label="Etternavn"
                clearable
                :disabled="loading"
                @change="updateSearch"
              />
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                v-model="search.participant.first_name"
                filled
                label="Fornavn"
                clearable
                :disabled="loading"
                @change="updateSearch"
              />
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="2">
              <MultiSelect
                label="Fylke"
                :items="counties"
                item-text="name"
                item-value="county_id"
                filled
                :disabled="loading"
                @change="updateSearchCounties"
              />
            </v-col>
            <v-col cols="12" md="6" lg="2">
              <MultiSelect
                label="Interessert i"
                :items="coursetypes"
                item-text="name"
                item-value="coursetype_id"
                filled
                :disabled="loading"
                @change="updateSearchCoursetypes"
              />
            </v-col>
            <v-col cols="6" sm="2" md="2" xl="1">
              <MultiSelect
                label="Kjønn"
                :items="genders"
                item-text="name"
                item-value="gender_id"
                filled
                :disabled="loading"
                @change="updateSearchGenders"
              />
            </v-col>
            <v-col cols="6" sm="2" md="2" xl="2">
              <v-select
                v-model="search.participant.participated_this_year"
                label="Aktiv i år"
                :items="[
                  { text: 'Ja', value: true },
                  { text: 'Nei', value: false },
                ]"
                filled
                item-text="text"
                item-value="value"
                clearable
                :disabled="loading"
                @change="updateSearchParticipatedThisYear"
              />
            </v-col>
            <v-col cols="6" sm="2" md="2" xl="1">
              <!-- TODO change to select -->
              <v-switch
                v-model="search.participant.deceased"
                label="Avdød"
                :disabled="loading"
                @change="updateSearch"
              />
            </v-col>
          </v-row>

          <v-data-table
            v-if="participants"
            :items="participants"
            item-key="participant_id"
            :loading="loading"
            :disable-pagination="loading"
            :disable-sort="loading"
            v-bind.sync="participantsTable"
            loading-text="Oppdaterer"
            @update:sort-desc="fetchData"
            @update:sort-by="fetchData"
            @update:page="fetchData"
            @update:items-per-page="updateItemsPerPage"
            @hook:mounted="fetchData"
          >
            <template v-slot:item.last_name="{ item }">
              <router-link
                :to="{
                  path: `/participants/${item.participant_id}`,
                }"
                style="text-decoration: none;"
              >
                {{ item.last_name }}, {{ item.first_name }}
              </router-link>
            </template>
            <template v-slot:item.email="{ item }">
              <span v-if="item.email" v-linkified>
                {{ item.email }}
              </span>
            </template>
            <template v-slot:item.phone="{ item }">
              <a v-if="item.phone" class="nowrap" :href="`tel:${item.phone}`"
                >{{ item.phone | phone }}
              </a>
            </template>
            <template v-slot:item.birthdate="{ item }">
              <template v-if="item.birthdate"> {{ item.birthdate | age }} år </template>
            </template>
            <template v-slot:item.postalcode="{ item }">
              <template v-if="item.postalcode">
                {{ item.postalcode.name }}
                <span
                  v-if="item.postalcode.municipality"
                  class="caption font-weight-light text--secondary text-uppercase ml-2"
                >
                  {{ item.postalcode.municipality.name }}
                  <template v-if="item.postalcode.municipality.county">
                    • {{ item.postalcode.municipality.county.name }}
                  </template>
                </span>
              </template>
            </template>
            <template v-slot:item.participations="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ getConfirmedParticipations(item.participations) }} /
                    {{ getSignedUpForParticipations(item.participations) }} /
                    {{ getInterestedInParticipations(item.participations) }} /
                    {{ getCancelledParticipations(item.participations) }}
                    <v-icon small>search</v-icon>
                  </span>
                </template>
                Bekreftet: {{ getConfirmedParticipations(item.participations) }}<br />
                Påmeldt: {{ getSignedUpForParticipations(item.participations) }}<br />
                Interessert: {{ getInterestedInParticipations(item.participations) }}<br />
                Avmeldt: {{ getCancelledParticipations(item.participations) }}<br />
              </v-tooltip>
            </template>
            <template v-slot:item.coursetypes="{ item }">
              <v-chip
                v-for="coursetype in item.coursetypes"
                :key="coursetype.coursetype_id"
                :color="coursetype.color"
                class="mr-1 mb-1"
              >
                {{ coursetype.name }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <ParticipantDialogButton
                type="update"
                color="white"
                @click="showParticipantDialog(item)"
              />
              <TooltipButton
                v-bind="deleteParticipantButton"
                @click="
                  getDeleteConfirmation(
                    item,
                    `Bekreft sletting av deltaker ${item.last_name}, ${item.first_name}`,
                    `Vil du virkelig slette deltakeren ${item.last_name}, ${item.first_name} for alltid?`
                  )
                "
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <EntityDialog
      ref="participantDialog"
      v-bind="participantDialog"
      @entityCreated="handleParticipantCreated"
      @entityUpdated="handleParticipantUpdated"
    />

    <DeleteConfirmationDialog
      ref="deleteConfirmationDialog"
      v-bind="deleteConfirmationDialog"
      @cancel="closeAndResetDeleteConfirmationDialog"
      @success="
        closeAndResetDeleteConfirmationDialog(
          `Du slettet deltakeren ${deleteConfirmationDialog.entityToDelete.last_name}, ${deleteConfirmationDialog.entityToDelete.first_name}`
        )
      "
    />
  </v-container>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'

import ExportContactinfoButton from '@/components/buttons/ExportContactinfoButton'
import ParticipantDialogButton from '@/components/buttons/ParticipantDialogButton'
import TooltipButton from '@/components/buttons/TooltipButton'
import MultiSelect from '@/components/controls/MultiSelect'
import EntityDialog from '@/components/dialogs/EntityDialog'
import DeleteConfirmationDialog from '@/components/dialogs/DeleteConfirmationDialog'

import deleteConfirmationDialog from '@/mixins/deleteConfirmationDialog.js'
import participantDialog from '@/mixins/dialogs/participantDialog.js'
import preferences from '@/mixins/preferences.js'
import responseHelper from '@/mixins/responseHelper.js'

import County from '@/models/County'
import Coursetype from '@/models/Coursetype'
import Gender from '@/models/Gender'
import Participant from '@/models/Participant'
import ParticipantCoursetype from '@/models/ParticipantCoursetype'
import Participation from '@/models/Participation'
import Postalcode from '@/models/Postalcode'

export default {
  components: {
    EntityDialog,
    ExportContactinfoButton,
    DeleteConfirmationDialog,
    MultiSelect,
    ParticipantDialogButton,
    TooltipButton,
  },
  mixins: [deleteConfirmationDialog, participantDialog, preferences, responseHelper],
  data() {
    return {
      loading: false,
      model: Participant,
    }
  },
  computed: {
    search() {
      return this.$currentUser.preferences.views.participants.search
    },
    sendEmailsButton() {
      return {
        button: {
          icon: 'mail',
          disabled: this.loading,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: `Send epost til alle deltakere i lista`,
        },
      }
    },
    participantsTable() {
      return {
        headers: [
          {
            text: 'Navn',
            value: 'last_name',
            sortable: true,
          },
          { text: 'Epost', value: 'email', sortable: false },
          { text: 'Telefon', value: 'phone', sortable: false },
          { text: 'Alder', value: 'birthdate', sortable: false },
          { text: 'Kjønn', value: 'gender.name', sortable: false },
          { text: 'Bosted', value: 'postalcode', sortable: false },
          { text: 'Påmeldinger', value: 'participations', sortable: false },
          { text: 'Interessert i', value: 'coursetypes', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [10, 25, 50, 100, 200],
        },
        options: {
          itemsPerPage: this.$currentUser.preferences.views.participants.itemsPerPage,
          page: 1,
          sortBy: ['last_name'],
          sortDesc: [false],
        },
      }
    },
    deleteParticipantButton() {
      return {
        button: {
          icon: 'delete_forever',
          disabled: !this.$currentUser.hasPermission('PARTICIPANT_DELETE') || this.loading,
          color: 'error',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('PARTICIPANT_DELETE')
            ? 'Slett deltaker'
            : 'Du mangler tillatelse til å slette deltakere',
        },
      }
    },
    participants() {
      let query = Participant.query()
        .with('postalcode')
        .with('postalcode.municipality')
        .with('postalcode.municipality.county')
        .with('participations')
        .with('gender')
        .with('coursetypes')

      this.participantsTable.options.sortBy.forEach((sortField, i) => {
        query = query.orderBy(
          sortField,
          this.participantsTable.options.sortDesc[i] ? 'desc' : 'asc'
        )
      })

      return query.get()
    },
    genders() {
      return Gender.query()
        .orderBy('name')
        .get()
    },
    counties() {
      return County.query()
        .orderBy('name')
        .get()
    },
    coursetypes() {
      return Coursetype.query()
        .orderBy('name')
        .get()
    },
  },
  methods: {
    async fetchData() {
      if (this.loading) return

      this.loading = true

      const participants = await Participant.fetchPaginated({
        pagination: this.participantsTable.options,
        search: this.search.participant,
      })
      this.participantsTable.serverItemsLength = participants.response.data.total

      if (this.participantsTable.serverItemsLength) {
        const participantIds = this.getDistinctIdsFromEntities(participants, 'participant_id')
        await ParticipantCoursetype.fetchBatchByKey('participant_id', participantIds)
        await Participation.fetchBatchByKey('participant_id', participantIds)

        const postalcodeIds = this.getDistinctIdsFromEntities(participants, 'postalcode_id')
        await Postalcode.fetchBatch(postalcodeIds)
      }

      this.loading = false
    },
    updateItemsPerPage() {
      if (
        this.$currentUser.preferences.views.participants.itemsPerPage !=
        this.participantsTable.options.itemsPerPage
      ) {
        this.$currentUser.preferences.views.participants.itemsPerPage = this.participantsTable.options.itemsPerPage
        this.updatePreferences()
        this.fetchData()
      }
    },
    updateSearchGenders(genders) {
      genders = genders.current.map(gender => {
        return gender.gender_id
      })

      if (!genders.length) {
        genders = undefined
      }

      this.search.participant.gender_id = genders
      this.updateSearch()
    },
    updateSearchCounties(counties) {
      counties = counties.current.map(county => {
        return county.county_id
      })

      if (!counties.length) {
        counties = undefined
      }

      this.search.participant.county_id = counties
      this.updateSearch()
    },
    updateSearchCoursetypes(coursetypes) {
      coursetypes = coursetypes.current.map(coursetype => {
        return coursetype.coursetype_id
      })

      if (!coursetypes.length) {
        coursetypes = undefined
      }

      this.search.participant.coursetype_id = coursetypes
      this.updateSearch()
    },
    updateSearchParticipatedThisYear(participatedThisYear) {
      if (typeof participatedThisYear != 'boolean') {
        this.search.participant.participated_this_year = undefined
      }

      this.updateSearch()
    },
    updateSearch() {
      this.updatePreferences()
      this.fetchData()
    },
    getInterestedInParticipations(participations) {
      if (Object.keys(participations).length == 0) return '0'

      const interested = participations.filter(participation => {
        return (
          !participation.confirmed_date && !participation.signup_date && !participation.cancel_date
        )
      })
      return interested.length
    },
    getSignedUpForParticipations(participations) {
      if (Object.keys(participations).length == 0) return '0'

      const signedUp = participations.filter(participation => {
        return (
          participation.signup_date && !participation.confirmed_date && !participation.cancel_date
        )
      })
      return signedUp.length
    },
    getConfirmedParticipations(participations) {
      if (Object.keys(participations).length == 0) return '0'

      const confirmed = participations.filter(participation => {
        return participation.confirmed_date && !participation.cancel_date
      })
      return confirmed.length
    },
    getCancelledParticipations(participations) {
      if (Object.keys(participations).length == 0) return '0'

      const cancelled = participations.filter(participation => {
        return participation.cancel_date
      })

      return cancelled.length
    },
    sendEmails() {
      if (this.loading) return

      const emailAddresses = this.participants
        .map(participant => participant.email)
        .filter(email => email != null)
        .join(';')

      window.location = `mailto:?bcc=${emailAddresses}`
    },
    async exportContactinfo() {
      if (this.loading) return

      this.loading = true

      let participants = await Participant.fetchAll(
        { search: this.search.participant },
        { save: false }
      )
      this.loading = false

      if (
        !participants ||
        !participants.response ||
        !participants.response.data ||
        !participants.response.data.length
      ) {
        return
      }

      participants = participants.response.data

      const postalcodeIds = participants
        .map(participant => participant.postalcode_id)
        .filter(postalcode_id => postalcode_id != null)

      await Postalcode.fetchBatch(postalcodeIds)

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: 'Kontaktinformasjon deltakere',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      }

      const data = participants.map(participant => {
        return {
          Fornavn: participant.first_name,
          Etternavn: participant.last_name,
          Telefon: participant.phone ? participant.phone : '',
          Epost: participant.email ? participant.email : '',
          Adresse: participant.address ? participant.address : '',
          Postnummer: participant.postalcode_id
            ? Postalcode.find(participant.postalcode_id).postalcode
            : '',
          Sted: participant.postalcode_id ? Postalcode.find(participant.postalcode_id).name : '',
          ReservertMotEpostmarkedsføring: participant.disallows_spam ? 'Ja' : 'Nei',
        }
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
  },
}
</script>
