<template>
  <v-text-field
    v-model="selectedItem"
    :label="label"
    :rules="rules"
    :disabled="disabled || loading"
    :minlength="minLength"
    :maxlength="maxLength"
    :error-messages="errorMessages"
    :loading="loading"
    :suffix="suffix"
  >
  </v-text-field>
</template>

<script>
import BaseModel from '@/models/BaseModel'

export default {
  name: 'ItemTextfield',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    model: {
      type: Function,
      default: BaseModel,
    },
    item: {
      type: BaseModel,
      default: () => {
        return new this.model()
      },
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    noDataText: {
      type: String,
      default: 'Fant ikke objektet',
    },
    suffixText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    minLength: {
      type: Number,
      default: undefined,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data: () => {
    return {
      errorMessages: [],
      suffix: '',
      loading: false,
    }
  },
  computed: {
    selectedItem: {
      get() {
        if (!this.item) return ''
        return this.item[this.itemText]
      },
      async set(value) {
        this.errorMessages = []
        this.suffix = ''

        if (value.length < this.minLength) return
        if (this.loading) return

        let search = {}
        search[this.itemText] = value

        this.loading = true
        const foundItem = await this.model.fetchOneByQuery(search)

        if (foundItem) {
          this.$emit('input', foundItem[this.itemValue])
          this.suffix = foundItem[this.suffixText]
        } else {
          this.errorMessages.push(this.noDataText)
        }
        this.loading = false
      },
    },
  },
}
</script>
